import React, { useEffect, useState } from "react";
import axiosInstance from "../../graphql/axiosConfig";
import { useNavigate } from "react-router-dom";
import EditMerchPopup from "./editMerch";

interface Merch {
  Description: string;
  Image: string[];
  Name: string;
  Price: number;
  open_to: string | null;
  id: string;
  active: string | null;
  available: number;
  soldQuantity?: number;
  customisable: string;
}

const MerchAdminPage: React.FC = () => {
  const [merchList, setMerchList] = useState<Merch[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEditPopupVisible, setIsEditPopupVisible] = useState<boolean>(false);
  const [newMerch, setNewMerch] = useState<Merch>({
    Description: "",
    Image: [],
    Name: "",
    Price: 0,
    open_to: "",
    id: "",
    active: null,
    available: 0,
    customisable: "",
  });
  const [selectedMerch, setSelectedMerch] = useState<Merch | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchMerch();
  }, []);

  const fetchMerch = async () => {
    const query = `
      query GetAllMerchAdmin {
        getAllMerchAdmin {
          id
          Name
          Price
          open_to
          Image
          Description
          available
          active
          customisable
        }
      }
    `;

    try {
      const response = await axiosInstance.post("", { query });

      // Check if data exists in the response
      if (!response.data || !response.data.data) {
        console.error("Error: No data returned from the server.");
        setMerchList([]);
        return;
      }

      const fetchedMerch: Merch[] = response.data.data.getAllMerchAdmin;

      console.log(fetchedMerch);

      // Ensure Images are defined and properly structured
      const updatedMerchList = fetchedMerch.map((merch) => ({
        ...merch,
        Image:
          merch.Image && merch.Image.length > 0
            ? merch.Image
            : ["/default-image.png"], // Fallback to a default image
        soldQuantity: 0, // Default soldQuantity, updated below
      }));

      console.log(merchList);

      // Fetch sold quantities for each merch item
      const soldQuantities = await Promise.all(
        updatedMerchList.map(async (merch) => {
          const ordersQuery = `
            query GetOrdersByItem($itemId: String!) {
              getOrdersByItem(ItemID: $itemId) {
                Quantity
              }
            }
          `;

          const orderResponse = await axiosInstance.post("", {
            query: ordersQuery,
            variables: { itemId: merch.id },
          });

          const totalQuantity =
            orderResponse.data.data?.getOrdersByItem?.reduce(
              (sum: number, order: { Quantity: number }) =>
                sum + order.Quantity,
              0
            );

          return { id: merch.id, soldQuantity: totalQuantity || 0 };
        })
      );

      // Merge sold quantities into the merch list
      const finalMerchList = updatedMerchList.map((merch) => {
        const soldData = soldQuantities.find((item) => item.id === merch.id);
        return { ...merch, soldQuantity: soldData?.soldQuantity || 0 };
      });

      setMerchList(finalMerchList);
    } catch (error) {
      console.error("Error fetching merch:", error);
      setMerchList([]); // Set to empty array on failure
    }
  };

  const handleActivate = async (itemId: string) => {
    const mutation = `
      mutation ActivateMerch($id: String!) {
        activateMerch(id: $id)
      }`;

    await axiosInstance.post("", {
      query: mutation,
      variables: { id: itemId },
    });
    console.log("activated");
    fetchMerch();
  };

  const handleDeactivate = async (itemId: string) => {
    const mutation = `
      mutation DeactivateMerch($id: String!) {
        deactivateMerch(id: $id)
      }`;

    await axiosInstance.post("", {
      query: mutation,
      variables: { id: itemId },
    });
    fetchMerch();
  };

  const handleAddMerch = async () => {
    const mutation = `
      mutation AddMerch(
  $name: String!,
  $price: Float!,
  $description: String,
  $image: [String!]!,
  $customisable: String,
  $available: Int!
) {
  AddMerch(
    name: $name,
    price: $price,
    description: $description,
    image: $image,
    customisable: $customisable,
    available: $available
  ) {
    id
  }
}
    `;

    // Corrected variable names to match GraphQL argument names
    const variables = {
      name: newMerch.Name, // Lowercase 'name'
      price: newMerch.Price, // Lowercase 'price'
      description: newMerch.Description, // Lowercase 'description'
      image: newMerch.Image.filter((img) => img !== null && img !== undefined), // Lowercase 'image'
      customisable: newMerch.customisable, // Lowercase 'customisable'
      open_to: newMerch.open_to, // Lowercase 'open_to'
      available: newMerch.available, // Lowercase 'available'
    };

    try {
      const response = await axiosInstance.post("", {
        query: mutation,
        variables: variables,
      });

      console.log("In try", response);

      if (response.data.errors) {
        const errorMessage = response.data.errors
          .map((err: any) => err.message)
          .join(", ");
        alert(`Error: ${errorMessage}`);
      } else {
        setShowAddModal(false);
        fetchMerch();
        alert("Merch item added successfully!");
      }
    } catch (err) {
      console.error(err);
      alert("An unexpected error occurred.");
    }
  };
  const handleAllCSV = async () => {
    function getEmailType(email:any) {
      if (email.endsWith('@smail.iitm.ac.in')) {
          return 'smail';
      } else if (email.endsWith('@alumni.iitm.ac.in')) {
          return 'alumni';
      } else {
          return 'public';
      }}
    const query = `
      query GetMerchOrdersCsv {
        getMerchOrdersCsv {
          order {
            Name
            ItemType
            Price
            Quantity
            Size
            customization
          }
          user {
            username
            email_id
            contact_no
            address
            pincode
          }
          payment{
          OrderDate
          }
          position
        }
      }
    `;
  
    const response = await axiosInstance.post("", {
      query,
    });
  
    const orders = response.data.data.getMerchOrdersCsv;
  
    // If no orders are available, return early.
    if (!orders || orders.length === 0) {
      alert("No orders found for this item.");
      return;
    }
  
    // Prepare CSV rows with the user details
    const csvRows = [
      ["Order Name","User Name","Type", "Email", "Contact No.","size","customization", "Price (₹)", "Quantity", "Amount","Discount","Date","pincode","IITM","address"],
      ...orders.map((order: any) => [
        order.order.Name+(order.position?"-"+order.position:""),
        order.user.username,
getEmailType(order.user.email_id),
        order.user.email_id,
        order.user.contact_no,
        order.order.Size,
        order.order.customization,
        order.order.Price,
        order.order.Quantity,
        order.order.Price * order.order.Quantity*(order.order.Quantity >= 5 && order.order.Quantity &&order.order.ItemType==="Combo" ? 0.95 : 1),
        order.order.Price*order.order.Quantity*(order.order.Quantity>=5 && order.order.Quantity && order.order.ItemType==="Combo" ?0.5:0),
        order.payment.OrderDate,
        order.user.pincode,
        order.user.pincode&&(order.user.pincode.includes("600036") ? "1":"0"),
        order.user.address,

      ]),
    ];
  
    // Convert the rows to a CSV string
    const csvString = csvRows.map((row) => row.join(",")).join("\n");
  
    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    const orderName = "all_orders";
    const filename = `${orderName}_${formattedDate}.csv`;
  
    // Create a download link and trigger it
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.click();
    // Generate the filename as `order.<Name>_<date>.csv`
  }
  const handleDownloadCSV = async (merch: Merch) => {
    const query = `
      query GetOrdersCsvByItem($itemId: String!) {
        getOrdersCsvByItem(ItemID: $itemId) {
          order {
            Name
            Price
            Quantity
            Size
            customization
          }
          user {
            username
            email_id
            contact_no
            address
            pincode
          }
          payment {
            OrderDate
          }
        }
      }
    `;

    const response = await axiosInstance.post("", {
      query,
      variables: { itemId: merch.id },
    });

    const orders = response.data.data.getOrdersCsvByItem;
    if (!orders || orders.length === 0) {
      alert("No orders found for this merch item.");
      return;
    }

    const csvRows = [
      [
        "Order Name",
        "User Name",
        "Email",
        "Contact No.",
        "Price (₹)",
        "Quantity",
        "Size",
        "Customization",
        "Amount",
        "pincode",
        "Date",
        "IITM",
        "address",
      ],
      ...orders.map((order: any) => [
        order.order.Name,
        order.user.username,
        order.user.email_id,
        order.user.contact_no,
        order.order.Price,
        order.order.Quantity,
        order.order.Size,
        order.order.customization,
        order.order.Price * order.order.Quantity,
        order.user.pincode,
        order.payment.OrderDate,
        order.user.pincode&&(order.user.pincode.includes("600036") ? "1":"0"),
        order.user.address,
      ]),
    ];

    const csvString = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
    const filename = `${merch.Name}_${formattedDate}.csv`;

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
  };

  async function LogOutHandler(e: React.FormEvent) {
    e.preventDefault();
    try {
      const res = await axiosInstance.post("", {
        query: `mutation Mutation { Logout }`,
      });

      if (res.data.errors) {
        // Handle errors if necessary
      }
      navigate("/auth");
    } catch (e: any) {}
  }

  return (
    <div className="min-h-screen p-8 bg-gray-800 text-black flex flex-col">
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => setShowAddModal(true)}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          Add Merch
        </button>
        <button
          onClick={handleAllCSV}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          CSV
        </button>
        <h1 className="text-3xl">Merch Admin Panel</h1>
        <div className="flex gap-2">
          <button
            onClick={() => navigate("/admin")}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Proshow
          </button>
          <button
            onClick={() => navigate("/admin/combo")}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Combo
          </button>
          <button
            onClick={LogOutHandler}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Logout
          </button>
        </div>
      </div>

      <table className="min-w-full bg-gray-200 rounded-lg shadow-lg">
        <thead>
          <tr className="bg-gray-300">
            <th className="py-3 px-6 text-left w-1/6">Name</th>
            <th className="py-3 px-6 text-left w-1/6">Price (₹)</th>
            {/* <th className="py-3 px-6 text-left w-1/6">Open to</th> */}
            <th className="py-3 px-6 text-left w-1/6">Available</th>
            <th className="py-3 px-6 text-left w-1/6">Sold</th>
            <th className="py-3 px-6 text-left w-1/6">Image</th>
            <th className="py-3 px-6 text-left w-1/6">CSV</th>
            <th className="py-3 px-6 text-left w-1/6">Edit</th>
            <th className="py-3 px-6 text-left w-1/6">Actions</th>
          </tr>
        </thead>
        <tbody>
          {merchList.map((merch) => (
            <tr key={merch.id}>
              <td className="py-4 px-6">{merch.Name}</td>
              <td className="py-4 px-6">₹{merch.Price}</td>
              {/* <td className="py-4 px-6">{merch.open_to}</td> */}
              <td className="py-4 px-6">{merch.available}</td>
              <td className="py-4 px-6">{merch.soldQuantity}</td>
              <td className="py-4 px-6">
                <img src={merch.Image[0]} alt={merch.Name} className="w-16" />
              </td>
              <td className="py-4 px-6">
                <button
                  onClick={() => handleDownloadCSV(merch)}
                  className="bg-blue-500 text-white px-2 py-1 rounded"
                >
                  CSV
                </button>
              </td>
              <td className="py-4 px-6">
                <button
                  onClick={() => {
                    setSelectedMerch(merch); // Set the selected combo for editing
                    setIsEditPopupVisible(true); // Show the edit popup
                  }}
                  className="bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Edit
                </button>
              </td>
              <td className="py-4 px-6">
                {merch.active == null || merch.active == "true" ? (
                  <button
                    onClick={() => handleDeactivate(merch.id)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Deactivate
                  </button>
                ) : (
                  <button
                    onClick={() => handleActivate(merch.id)}
                    className="bg-green-500 text-white px-2 py-1 rounded"
                  >
                    Activate
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <EditMerchPopup
        isVisible={isEditPopupVisible}
        onClose={() => setIsEditPopupVisible(false)}
        onMerchEdit={fetchMerch}
        merch={selectedMerch!} // Pass the selected combo
      />

      {showAddModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded p-6 w-[60%] max-h-[80vh] overflow-y-auto">
            <h2 className="text-xl mb-4">Add Merch</h2>
            <input
              type="text"
              placeholder="Name"
              value={newMerch.Name}
              onChange={(e) =>
                setNewMerch({ ...newMerch, Name: e.target.value })
              }
              className="mb-2 w-full p-2 border"
            />
            <input
              type="text"
              placeholder="Description"
              value={newMerch.Description}
              onChange={(e) =>
                setNewMerch({ ...newMerch, Description: e.target.value })
              }
              className="mb-2 w-full p-2 border"
            />
            <input
              type="number"
              placeholder="Price"
              value={newMerch.Price}
              onChange={(e) =>
                setNewMerch({ ...newMerch, Price: parseFloat(e.target.value) })
              }
              className="mb-2 w-full p-2 border"
            />
            <input
              type="number"
              placeholder="Available"
              value={newMerch.available}
              onChange={(e) =>
                setNewMerch({
                  ...newMerch,
                  available: parseInt(e.target.value, 10),
                })
              }
              className="mb-2 w-full p-2 border"
            />
            <div className="mb-4">
              <label className="block mb-2">Image URLs:</label>
              {newMerch.Image.map((url, index) => (
                <div key={index} className="flex gap-2 mb-2">
                  <input
                    type="text"
                    placeholder="Image URL"
                    value={url}
                    onChange={(e) =>
                      setNewMerch({
                        ...newMerch,
                        Image: newMerch.Image.map((img, i) =>
                          i === index ? e.target.value : img
                        ),
                      })
                    }
                    className="border p-2 flex-1"
                  />
                  <button
                    onClick={() =>
                      setNewMerch({
                        ...newMerch,
                        Image: newMerch.Image.filter((_, i) => i !== index),
                      })
                    }
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                onClick={() =>
                  setNewMerch({ ...newMerch, Image: [...newMerch.Image, ""] })
                }
                className="bg-green-500 text-white px-4 py-2 rounded mt-2"
              >
                Add Image
              </button>
            </div>

            {/* <select
            value={newMerch.open_to||""}
              onChange={(e) => setNewMerch({...newMerch,open_to:e.target.value}) }
            className="border p-2 w-full mb-2"
          >
            <option value="" disabled>
              Open to
            </option>
            <option value="smail">Smail</option>
            <option value="alumni">Alumni</option>
            <option value="public">Public</option>
          </select> */}

            <select
              value={newMerch.customisable || ""}
              onChange={(e) =>
                setNewMerch({ ...newMerch, customisable: e.target.value })
              }
              className="border p-2 w-full mb-2"
            >
              <option value="" disabled>
                Customisable
              </option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>

            <div className="flex justify-end gap-5">
              <button
                onClick={handleAddMerch}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Add
              </button>
              <button
                onClick={() => setShowAddModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MerchAdminPage;
