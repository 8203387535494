import React, { useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
import Tick from '../../Images/tick.png';
import Cross from '../../Images/cross.png';
import EXL from '../../Images/Exclame.png';

interface PopupProps {
  message: string;
  duration?: number;
  type: string;
}

const Popup: React.FC<PopupProps> = ({ message, type, duration = 3000 }) => {
  const [popup, setPopup] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPopup(false); // Automatically hide after the duration
    }, duration);
    return () => clearTimeout(timer); // Cleanup the timer
  }, [duration]);

  if (!popup) {
    return null; // When popup is false, return null (no rendering)
  }

  let content;
  if (type === "success") {
    content = (
      <div className="text-white p-6 rounded-lg shadow-lg max-w-sm w-full transform transition-all duration-300 ease-in-out flex flex-col items-center"
        style={{ background: "#0f4d0f" }}> {/* Red theme */}
        <img className="w-12 h-12 mb-3" src={Tick} alt="Success Tick" />
      <h2 className="text-xl font-semibold mb-2 text-center">{message}</h2>
        {/* <p className="text-center">{message}</p> */}
      </div>
    );
  } else if (type === 'fails') {
    content = (
      <div className="bg-red-800 text-white p-6 rounded-lg shadow-lg max-w-sm w-full transform transition-all duration-300 ease-in-out flex flex-col items-center">
        <img className="w-12 h-12 mb-3" src={Cross} alt="Failed Cross" />
      <h2 className="text-xl font-semibold mb-2 text-center">{message}</h2>
        {/* <p className="text-center">{message}</p> */}
      </div>
    );
  } else if (type === 'normal') {
    content = (
      <div className="text-white p-6 rounded-lg shadow-lg max-w-sm w-full transform transition-all duration-300 ease-in-out flex flex-col items-center"
        style={{ background: "#ba8e23" }}> {/* Dark red theme */}
        <img className="w-12 h-12 mb-3" src={EXL} alt="Cautious" />
        <h2 className="text-xl font-semibold mb-2 text-center">Please Note</h2>
        <p className="text-center">{message}</p>
      </div>
    );
  } else if (type === 'loading') {
    content = (
      <div className="text-white p-6 rounded-lg shadow-lg max-w-sm w-full transform transition-all duration-300 ease-in-out flex flex-col items-center"
        style={{ background: "#C62828" }}> {/* Red for loading */}
        <Bars
          height="50"
          width="50"
          color="#ecf0f1"
          ariaLabel="bars-loading"
          visible={true}
        />
        <h2 className="text-xl font-semibold mb-2 text-center">Loading...</h2>
      </div>
    );
  }

  return (
    <div className="popup1 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      {content}
    </div>
  );
};

export default Popup;
