import React from "react";
import logo from "./logo.svg";
import "./App.css";
import './index.css';

import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthPage from "./pages/auth/auth";
import ForgotPassword from "./pages/auth/forgot/forgot"
import ResetPassword from "./pages/auth/forgot/resetpassword";
import VerifyPage from "./pages/auth/verification/verification";
import MainPage from "./pages/Home/Home";
import Tickets from "./pages/tickets/tickets";
import rock from './Images/ProshowSlider/rock+combined+final.png'
import edm from './Images/ProshowSlider/EDM+COMBINED.png'

import Navbar from './components/Navbar/Navbar'
import Popup from "./ui-elements/popup/popup";
import Combos from "./pages/combos/combos";
import Cart from "./pages/cart/cart";
import MyOrders from "./pages/order/order";
import Home from './pages/Home/Home'
import Merchs from "./pages/Merchs/Merchs";
import ProShowsAdminPage from "./pages/admin/pro";
import CombosAdminPage from "./pages/admin/com";
import { NavbarProvider } from "./components/contexts/NavbarContext";
import { useGetUserQuery } from "./generated/graphql";
import EdmDummy from "./components/edmDummy";
import Timer  from "./components/Timer/Timer"
import Merch from "./pages/Merch/Merch";
import MerchCombo from "./pages/MerchCombo/MerchCombo";
import MerchAdminPage from "./pages/admin/mer";

 
function App() {
  const{data,error,loading}=useGetUserQuery()
 
  return (
    <div className="App">
    <NavbarProvider>
      <BrowserRouter>
        <Routes>          
          <Route path="/forgotpassword"  element={<ForgotPassword/>}/>
          <Route path="/resetpassword/:id1/:id2" element={<ResetPassword/>}/>
          <Route path="/auth" element={<AuthPage/>}/>
          <Route path="/verifyEmail/:id1/:id2" element={<VerifyPage/>} />
          <Route path="/" element={<Home/>}/>
          

          {/* <Route path="/popup" element={<Popup message="vctfvbgunh"/>} />
          <Route path="/popup" element={<Popup message="vctfvbgunh"/>} /> */}
          <Route path="/proshows/:night" element={<Tickets/>}/>
          {/* <Route path="/proshows/" element={<EdmDummy/>}/> */}

          <Route path="/combos/:id" element={<Combos/>}/>
          <Route path="/cart" element={<Cart/>}/>
          <Route path="/myorders" element={<MyOrders/>}/>
          <Route path="/merchcombos/:id" element={<MerchCombo/>}/>

          <Route path="/popup" element={<Popup message="Haaii" type={"success"}/>} />
          <Route path="/proshows/:night" element={<Tickets/>}/>
          <Route path="/merch" element={<Merchs/>}/>
          <Route path="/merch/:id" element={<Merch/>}/>
          <Route path="/timer" element={<Timer targetDate="2024-11-30T11:59:59"/>}/>

          {(data?.GetUser.email_id === "devops@saarang.org"||data?.GetUser.email_id==="mns@saarang.org") && (
          <>
            <Route path="/admin" element={<ProShowsAdminPage />} />
            <Route path="/admin/combo" element={<CombosAdminPage />} />
            <Route path="/admin/merch" element={<MerchAdminPage />} />
          </>
         )} 

         </Routes>
       </BrowserRouter> 
      </NavbarProvider> 

    </div>
  );
}

export default App;

