import React, { useState } from 'react';
import './ImagePopUp.scss'; // Optional for custom styling
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/device/reducers';

// Define prop types
interface ImagePopupProps {
  imageSrc: string; // Define imageSrc as a string
  linkText:String;
}

const ImagePopup: React.FC<ImagePopupProps> = ({ imageSrc ,linkText}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { device } = useSelector((state: RootState) => state.windowSize);

  // Function to open the popup
  const openPopup = () => {
    setIsOpen(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setIsOpen(false);
  };

  if (linkText ==="OAT Layout"){
    return (
        <div>
          {/* Button to open the popup */}
          <button onClick={()=>setIsOpen(true)}  className={`popup-link ${device}`}  >{linkText}</button>
    
          {/* Image popup */}
          {isOpen && (
            <div className="popup-overlay">
              <div className="popup-content">
                <span className="close-btn" onClick={closePopup}>
                  &times;
                </span>
                <img src={imageSrc} alt="Popup" className="popup-image" />
              </div>
            </div>
          )}
        </div>
    )
  }

  if (linkText ==="Oversized Chart"){
    return (
        <div>
          {/* Button to open the popup */}
          <button onClick={()=>setIsOpen(true)}  className={`popup-link ${device}`}  >{linkText}</button>
    
          {/* Image popup */}
          {isOpen && (
            <div className="popup-overlay">
              <div className="popup-content">
                <span className="close-btn" onClick={closePopup}>
                  &times;
                </span>
                <img src={"https://saarang2024.s3.ap-south-1.amazonaws.com/size.jpg"} alt="Popup" className="popup-image" />
              </div>
            </div>
          )}
        </div>
    )
  }

  return (
    <div>
      {/* Button to open the popup */}
      <button onClick={()=> window.open("https://saarang2024.s3.ap-south-1.amazonaws.com/SIZE+CHART.pdf","_")}  className={`popup-link ${device}`}  >{linkText}</button>

      {/* Image popup */}
      {isOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <span className="close-btn" onClick={closePopup}>
              &times;
            </span>
            <img src={imageSrc} alt="Popup" className="popup-image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImagePopup;
