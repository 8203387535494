import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

export type Cart = {
  __typename?: 'Cart';
  ItemID: Scalars['String']['output'];
  ItemType: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Quantity: Scalars['Float']['output'];
  Size?: Maybe<Scalars['String']['output']>;
  customization?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  userID: Scalars['String']['output'];
};

export type CartItemWithPrice = {
  __typename?: 'CartItemWithPrice';
  ItemID: Scalars['String']['output'];
  ItemType: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Position: Scalars['String']['output'];
  Price: Scalars['Float']['output'];
  Quantity: Scalars['Float']['output'];
  Size?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  userID: Scalars['String']['output'];
};

export type Combo = {
  __typename?: 'Combo';
  Description: Scalars['String']['output'];
  Image: Scalars['String']['output'];
  Items: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Price: Scalars['Float']['output'];
  active?: Maybe<Scalars['String']['output']>;
  available?: Maybe<Scalars['Float']['output']>;
  customisable?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mobileImage?: Maybe<Scalars['String']['output']>;
  open_to: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Merch = {
  __typename?: 'Merch';
  Description: Scalars['String']['output'];
  Image: Array<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
  Price: Scalars['Float']['output'];
  active?: Maybe<Scalars['String']['output']>;
  available?: Maybe<Scalars['Float']['output']>;
  customisable: Scalars['String']['output'];
  id: Scalars['String']['output'];
  open_to?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AddCombo: Scalars['String']['output'];
  AddMerch: Merch;
  AddProShow: ProShows;
  AddtoCart: Scalars['Boolean']['output'];
  ClearCart: Scalars['String']['output'];
  EditCombo: Scalars['String']['output'];
  EditMerch: Scalars['String']['output'];
  IsExistsEmail: Scalars['Boolean']['output'];
  IsUserLoggedIn: Scalars['Boolean']['output'];
  Login: Scalars['Boolean']['output'];
  Logout: Scalars['String']['output'];
  Register: Users;
  RemoveFromCart: Scalars['String']['output'];
  ResetPasswordWithOTP: Scalars['Boolean']['output'];
  VerifyResetPasswordLink: Scalars['Boolean']['output'];
  activateCombo: Scalars['String']['output'];
  activateMerch: Scalars['String']['output'];
  activateProShow: Scalars['String']['output'];
  addQr: Scalars['String']['output'];
  approveTicket: QrTickets;
  check_reg: Users;
  checkout: Scalars['String']['output'];
  deactivateCombo: Scalars['String']['output'];
  deactivateMerch: Scalars['String']['output'];
  deactivateProShow: Scalars['String']['output'];
  deleteMerch: Scalars['String']['output'];
  deletecombo: Scalars['String']['output'];
  deleteproshow: Scalars['String']['output'];
  editProShow: ProShows;
  sendMails: Scalars['Boolean']['output'];
  sendOTP: Scalars['Boolean']['output'];
  sendResetLink: Scalars['Boolean']['output'];
  setAddress: Scalars['Boolean']['output'];
};


export type MutationAddComboArgs = {
  available: Scalars['Float']['input'];
  customisable?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  items: Itemjson;
  mobileImage?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  open_to: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  type: Scalars['String']['input'];
};


export type MutationAddMerchArgs = {
  available: Scalars['Int']['input'];
  customisable?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  image: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};


export type MutationAddProShowArgs = {
  available?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  mobileImage?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  position: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  smail_price?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationAddtoCartArgs = {
  ItemID: Scalars['String']['input'];
  ItemType: Scalars['String']['input'];
  Name?: InputMaybe<Scalars['String']['input']>;
  Quantity: Scalars['Float']['input'];
  Size?: InputMaybe<Scalars['String']['input']>;
  customizable?: InputMaybe<Scalars['Boolean']['input']>;
  customization?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditComboArgs = {
  available?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  mobileImage?: InputMaybe<Scalars['String']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationEditMerchArgs = {
  available?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Array<Scalars['String']['input']>>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationIsExistsEmailArgs = {
  email_id: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  contact_no: Scalars['String']['input'];
  email_id: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationRemoveFromCartArgs = {
  ItemID: Scalars['String']['input'];
  ItemType: Scalars['String']['input'];
  Name?: InputMaybe<Scalars['String']['input']>;
  Quantity: Scalars['Float']['input'];
  Size?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResetPasswordWithOtpArgs = {
  email_id: Scalars['String']['input'];
  otp: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationVerifyResetPasswordLinkArgs = {
  email_base64: Scalars['String']['input'];
  password: Scalars['String']['input'];
  reset_hash: Scalars['String']['input'];
};


export type MutationActivateComboArgs = {
  id: Scalars['String']['input'];
};


export type MutationActivateMerchArgs = {
  id: Scalars['String']['input'];
};


export type MutationActivateProShowArgs = {
  id: Scalars['String']['input'];
};


export type MutationAddQrArgs = {
  Gate: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pass: Scalars['String']['input'];
  pass_type: Scalars['String']['input'];
  program_name: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
  ticket_id: Scalars['String']['input'];
};


export type MutationApproveTicketArgs = {
  ticket_id: Scalars['String']['input'];
};


export type MutationCheck_RegArgs = {
  email_base64: Scalars['String']['input'];
  login_hash: Scalars['String']['input'];
};


export type MutationCheckoutArgs = {
  coupon_code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeactivateComboArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeactivateMerchArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeactivateProShowArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMerchArgs = {
  name: Scalars['String']['input'];
};


export type MutationDeletecomboArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteproshowArgs = {
  name: Scalars['String']['input'];
};


export type MutationEditProShowArgs = {
  available?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  mobileImage?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  smail_price?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationSendMailsArgs = {
  Night: Scalars['String']['input'];
  pass: Scalars['String']['input'];
};


export type MutationSendOtpArgs = {
  email_id: Scalars['String']['input'];
};


export type MutationSendResetLinkArgs = {
  email: Scalars['String']['input'];
};


export type MutationSetAddressArgs = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  pincode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type Order = {
  __typename?: 'Order';
  ItemID: Scalars['String']['output'];
  ItemType: Scalars['String']['output'];
  Name?: Maybe<Scalars['String']['output']>;
  Price: Scalars['Float']['output'];
  Quantity: Scalars['Float']['output'];
  Size?: Maybe<Scalars['String']['output']>;
  customization?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  razorpay_OrderID: Scalars['String']['output'];
  userID: Scalars['String']['output'];
};

export type OrderWithUserDetails = {
  __typename?: 'OrderWithUserDetails';
  order: Order;
  payment: Payments;
  position?: Maybe<Scalars['String']['output']>;
  user: Users;
};

export type Payments = {
  __typename?: 'Payments';
  CouponCode?: Maybe<Scalars['String']['output']>;
  OrderDate: Scalars['String']['output'];
  OrderTotal: Scalars['Float']['output'];
  PaymentStatus: Scalars['String']['output'];
  delivery_charge?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  payment_datetime?: Maybe<Scalars['String']['output']>;
  razorpay_OrderID: Scalars['String']['output'];
  razorpay_payment_id?: Maybe<Scalars['String']['output']>;
  userID: Scalars['String']['output'];
};

export type ProShows = {
  __typename?: 'ProShows';
  Description: Scalars['String']['output'];
  Image: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Position: Scalars['String']['output'];
  Position_logo?: Maybe<Scalars['String']['output']>;
  Price: Scalars['Float']['output'];
  Smail_price?: Maybe<Scalars['Float']['output']>;
  active?: Maybe<Scalars['String']['output']>;
  available?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  mobileImage?: Maybe<Scalars['String']['output']>;
};

export type QrTickets = {
  __typename?: 'QRTickets';
  is_scanned: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  ticket_description: Scalars['String']['output'];
  ticket_id: Scalars['String']['output'];
  ticket_no: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  GetCartItemsByUser: Array<Cart>;
  GetCartItemsByUserWithPrice: Array<CartItemWithPrice>;
  GetUser: Users;
  IsAlumMail: Scalars['Boolean']['output'];
  IsItObscMail: Scalars['Boolean']['output'];
  IsItStudentMail: Scalars['Boolean']['output'];
  getActiveProShows?: Maybe<Array<ProShows>>;
  getAllCombos?: Maybe<Array<Combo>>;
  getAllMerch?: Maybe<Array<Merch>>;
  getAllMerchAdmin?: Maybe<Array<Merch>>;
  getCombobyId?: Maybe<Combo>;
  getCombosByType?: Maybe<Array<Combo>>;
  getMerchByID?: Maybe<Merch>;
  getMerchByName?: Maybe<Merch>;
  getOrdersByItem?: Maybe<Array<Order>>;
  getOrdersCsv?: Maybe<Array<OrderWithUserDetails>>;
  getOrdersCsvByItem?: Maybe<Array<OrderWithUserDetails>>;
  getPaymentStatusByRazorpayId: Scalars['String']['output'];
  getProShowbyId?: Maybe<ProShows>;
  getProShows?: Maybe<Array<ProShows>>;
  getUserOrders?: Maybe<Array<Order>>;
  getproshowbyName?: Maybe<Array<ProShows>>;
  getproshowbyNamePosition?: Maybe<ProShows>;
};


export type QueryGetCombobyIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCombosByTypeArgs = {
  types: Array<Scalars['String']['input']>;
};


export type QueryGetMerchByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMerchByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryGetOrdersByItemArgs = {
  ItemID: Scalars['String']['input'];
};


export type QueryGetOrdersCsvByItemArgs = {
  ItemID: Scalars['String']['input'];
};


export type QueryGetPaymentStatusByRazorpayIdArgs = {
  razorpay_OrderID: Scalars['String']['input'];
};


export type QueryGetProShowbyIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetproshowbyNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryGetproshowbyNamePositionArgs = {
  name: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type Users = {
  __typename?: 'Users';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contact_no: Scalars['String']['output'];
  created_at: Scalars['DateTimeISO']['output'];
  email_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  otp: Scalars['String']['output'];
  pincode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type Itemjson = {
  merch_list: Array<Scalars['String']['input']>;
  position_list: Array<Scalars['String']['input']>;
  proshows_list: Array<Scalars['String']['input']>;
};

export type RegisterMutationVariables = Exact<{
  contactNo: Scalars['String']['input'];
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
  emailId: Scalars['String']['input'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', Register: { __typename?: 'Users', username: string } };

export type LoginMutationVariables = Exact<{
  password: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', Login: boolean };

export type Check_RegMutationVariables = Exact<{
  emailBase64: Scalars['String']['input'];
  loginHash: Scalars['String']['input'];
}>;


export type Check_RegMutation = { __typename?: 'Mutation', check_reg: { __typename?: 'Users', email_id: string } };

export type AddtoCartMutationVariables = Exact<{
  quantity: Scalars['Float']['input'];
  itemId: Scalars['String']['input'];
  itemType: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  customization?: InputMaybe<Scalars['String']['input']>;
  customizable?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type AddtoCartMutation = { __typename?: 'Mutation', AddtoCart: boolean };

export type RemoveFromCartMutationVariables = Exact<{
  quantity: Scalars['Float']['input'];
  itemId: Scalars['String']['input'];
  itemType: Scalars['String']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type RemoveFromCartMutation = { __typename?: 'Mutation', RemoveFromCart: string };

export type IsUserLoggedInMutationVariables = Exact<{ [key: string]: never; }>;


export type IsUserLoggedInMutation = { __typename?: 'Mutation', IsUserLoggedIn: boolean };

export type SendResetLinkMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SendResetLinkMutation = { __typename?: 'Mutation', sendResetLink: boolean };

export type VerifyResetPasswordLinkMutationVariables = Exact<{
  password: Scalars['String']['input'];
  resetHash: Scalars['String']['input'];
  emailBase64: Scalars['String']['input'];
}>;


export type VerifyResetPasswordLinkMutation = { __typename?: 'Mutation', VerifyResetPasswordLink: boolean };

export type CheckoutMutationVariables = Exact<{
  couponCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type CheckoutMutation = { __typename?: 'Mutation', checkout: string };

export type ClearCartMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearCartMutation = { __typename?: 'Mutation', ClearCart: string };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', Logout: string };

export type SetAddressMutationVariables = Exact<{
  pincode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  city: Scalars['String']['input'];
  address: Scalars['String']['input'];
}>;


export type SetAddressMutation = { __typename?: 'Mutation', setAddress: boolean };

export type GetProShowsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProShowsQuery = { __typename?: 'Query', getProShows?: Array<{ __typename?: 'ProShows', Description: string, mobileImage?: string | null, Image: string, Name: string, Position: string, Price: number, Smail_price?: number | null, id: string }> | null };

export type GetproshowbyNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type GetproshowbyNameQuery = { __typename?: 'Query', getproshowbyName?: Array<{ __typename?: 'ProShows', Name: string, Position: string, Price: number, Smail_price?: number | null, id: string, active?: string | null, Image: string, mobileImage?: string | null, Description: string }> | null };

export type GetCombosByTypeQueryVariables = Exact<{
  types: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type GetCombosByTypeQuery = { __typename?: 'Query', getCombosByType?: Array<{ __typename?: 'Combo', id: string, mobileImage?: string | null, Image: string, Name: string, customisable?: string | null }> | null };

export type GetCartItemsByUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCartItemsByUserQuery = { __typename?: 'Query', GetCartItemsByUser: Array<{ __typename?: 'Cart', id: string, userID: string, ItemID: string, ItemType: string, Quantity: number, customization?: string | null, Size?: string | null }> };

export type GetProShowbyIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetProShowbyIdQuery = { __typename?: 'Query', getProShowbyId?: { __typename?: 'ProShows', Name: string, Position: string, Price: number, Smail_price?: number | null, Image: string, mobileImage?: string | null } | null };

export type IsItStudentMailQueryVariables = Exact<{ [key: string]: never; }>;


export type IsItStudentMailQuery = { __typename?: 'Query', IsItStudentMail: boolean };

export type IsItObscMailQueryVariables = Exact<{ [key: string]: never; }>;


export type IsItObscMailQuery = { __typename?: 'Query', IsItObscMail: boolean };

export type IsAlumMailQueryVariables = Exact<{ [key: string]: never; }>;


export type IsAlumMailQuery = { __typename?: 'Query', IsAlumMail: boolean };

export type GetMerchByIdQueryVariables = Exact<{
  getMerchByIdId: Scalars['String']['input'];
}>;


export type GetMerchByIdQuery = { __typename?: 'Query', getMerchByID?: { __typename?: 'Merch', id: string, Name: string, Price: number, Image: Array<string>, Description: string, available?: number | null, active?: string | null, customisable: string, open_to?: string | null } | null };

export type GetAllCombosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCombosQuery = { __typename?: 'Query', getAllCombos?: Array<{ __typename?: 'Combo', Price: number, mobileImage?: string | null, Description: string, id: string, Name: string, Image: string }> | null };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', GetUser: { __typename?: 'Users', email_id: string, address?: string | null, city?: string | null, state?: string | null, pincode?: string | null, id: string, contact_no: string, username: string } };

export type GetCombobyIdQueryVariables = Exact<{
  Id: Scalars['String']['input'];
}>;


export type GetCombobyIdQuery = { __typename?: 'Query', getCombobyId?: { __typename?: 'Combo', Description: string, Image: string, Items: string, Name: string, Price: number, active?: string | null, available?: number | null, customisable?: string | null, id: string, mobileImage?: string | null, open_to: string, type: string } | null };

export type GetAllMerchQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllMerchQuery = { __typename?: 'Query', getAllMerch?: Array<{ __typename?: 'Merch', Description: string, Image: Array<string>, Name: string, Price: number, active?: string | null, available?: number | null, customisable: string, id: string, open_to?: string | null }> | null };


export const RegisterDocument = gql`
    mutation Register($contactNo: String!, $username: String!, $password: String!, $emailId: String!) {
  Register(
    contact_no: $contactNo
    username: $username
    password: $password
    email_id: $emailId
  ) {
    username
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      contactNo: // value for 'contactNo'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const LoginDocument = gql`
    mutation Login($password: String!, $email: String!) {
  Login(password: $password, email: $email)
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const Check_RegDocument = gql`
    mutation Check_reg($emailBase64: String!, $loginHash: String!) {
  check_reg(email_base64: $emailBase64, login_hash: $loginHash) {
    email_id
  }
}
    `;
export type Check_RegMutationFn = Apollo.MutationFunction<Check_RegMutation, Check_RegMutationVariables>;

/**
 * __useCheck_RegMutation__
 *
 * To run a mutation, you first call `useCheck_RegMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheck_RegMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkRegMutation, { data, loading, error }] = useCheck_RegMutation({
 *   variables: {
 *      emailBase64: // value for 'emailBase64'
 *      loginHash: // value for 'loginHash'
 *   },
 * });
 */
export function useCheck_RegMutation(baseOptions?: Apollo.MutationHookOptions<Check_RegMutation, Check_RegMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Check_RegMutation, Check_RegMutationVariables>(Check_RegDocument, options);
      }
export type Check_RegMutationHookResult = ReturnType<typeof useCheck_RegMutation>;
export type Check_RegMutationResult = Apollo.MutationResult<Check_RegMutation>;
export type Check_RegMutationOptions = Apollo.BaseMutationOptions<Check_RegMutation, Check_RegMutationVariables>;
export const AddtoCartDocument = gql`
    mutation AddtoCart($quantity: Float!, $itemId: String!, $itemType: String!, $name: String, $size: String, $customization: String, $customizable: Boolean) {
  AddtoCart(
    Quantity: $quantity
    ItemID: $itemId
    ItemType: $itemType
    Name: $name
    Size: $size
    customization: $customization
    customizable: $customizable
  )
}
    `;
export type AddtoCartMutationFn = Apollo.MutationFunction<AddtoCartMutation, AddtoCartMutationVariables>;

/**
 * __useAddtoCartMutation__
 *
 * To run a mutation, you first call `useAddtoCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddtoCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addtoCartMutation, { data, loading, error }] = useAddtoCartMutation({
 *   variables: {
 *      quantity: // value for 'quantity'
 *      itemId: // value for 'itemId'
 *      itemType: // value for 'itemType'
 *      name: // value for 'name'
 *      size: // value for 'size'
 *      customization: // value for 'customization'
 *      customizable: // value for 'customizable'
 *   },
 * });
 */
export function useAddtoCartMutation(baseOptions?: Apollo.MutationHookOptions<AddtoCartMutation, AddtoCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddtoCartMutation, AddtoCartMutationVariables>(AddtoCartDocument, options);
      }
export type AddtoCartMutationHookResult = ReturnType<typeof useAddtoCartMutation>;
export type AddtoCartMutationResult = Apollo.MutationResult<AddtoCartMutation>;
export type AddtoCartMutationOptions = Apollo.BaseMutationOptions<AddtoCartMutation, AddtoCartMutationVariables>;
export const RemoveFromCartDocument = gql`
    mutation RemoveFromCart($quantity: Float!, $itemId: String!, $itemType: String!, $size: String, $name: String) {
  RemoveFromCart(
    Quantity: $quantity
    ItemID: $itemId
    ItemType: $itemType
    Size: $size
    Name: $name
  )
}
    `;
export type RemoveFromCartMutationFn = Apollo.MutationFunction<RemoveFromCartMutation, RemoveFromCartMutationVariables>;

/**
 * __useRemoveFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromCartMutation, { data, loading, error }] = useRemoveFromCartMutation({
 *   variables: {
 *      quantity: // value for 'quantity'
 *      itemId: // value for 'itemId'
 *      itemType: // value for 'itemType'
 *      size: // value for 'size'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRemoveFromCartMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromCartMutation, RemoveFromCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFromCartMutation, RemoveFromCartMutationVariables>(RemoveFromCartDocument, options);
      }
export type RemoveFromCartMutationHookResult = ReturnType<typeof useRemoveFromCartMutation>;
export type RemoveFromCartMutationResult = Apollo.MutationResult<RemoveFromCartMutation>;
export type RemoveFromCartMutationOptions = Apollo.BaseMutationOptions<RemoveFromCartMutation, RemoveFromCartMutationVariables>;
export const IsUserLoggedInDocument = gql`
    mutation IsUserLoggedIn {
  IsUserLoggedIn
}
    `;
export type IsUserLoggedInMutationFn = Apollo.MutationFunction<IsUserLoggedInMutation, IsUserLoggedInMutationVariables>;

/**
 * __useIsUserLoggedInMutation__
 *
 * To run a mutation, you first call `useIsUserLoggedInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIsUserLoggedInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [isUserLoggedInMutation, { data, loading, error }] = useIsUserLoggedInMutation({
 *   variables: {
 *   },
 * });
 */
export function useIsUserLoggedInMutation(baseOptions?: Apollo.MutationHookOptions<IsUserLoggedInMutation, IsUserLoggedInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IsUserLoggedInMutation, IsUserLoggedInMutationVariables>(IsUserLoggedInDocument, options);
      }
export type IsUserLoggedInMutationHookResult = ReturnType<typeof useIsUserLoggedInMutation>;
export type IsUserLoggedInMutationResult = Apollo.MutationResult<IsUserLoggedInMutation>;
export type IsUserLoggedInMutationOptions = Apollo.BaseMutationOptions<IsUserLoggedInMutation, IsUserLoggedInMutationVariables>;
export const SendResetLinkDocument = gql`
    mutation SendResetLink($email: String!) {
  sendResetLink(email: $email)
}
    `;
export type SendResetLinkMutationFn = Apollo.MutationFunction<SendResetLinkMutation, SendResetLinkMutationVariables>;

/**
 * __useSendResetLinkMutation__
 *
 * To run a mutation, you first call `useSendResetLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetLinkMutation, { data, loading, error }] = useSendResetLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendResetLinkMutation, SendResetLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetLinkMutation, SendResetLinkMutationVariables>(SendResetLinkDocument, options);
      }
export type SendResetLinkMutationHookResult = ReturnType<typeof useSendResetLinkMutation>;
export type SendResetLinkMutationResult = Apollo.MutationResult<SendResetLinkMutation>;
export type SendResetLinkMutationOptions = Apollo.BaseMutationOptions<SendResetLinkMutation, SendResetLinkMutationVariables>;
export const VerifyResetPasswordLinkDocument = gql`
    mutation VerifyResetPasswordLink($password: String!, $resetHash: String!, $emailBase64: String!) {
  VerifyResetPasswordLink(
    password: $password
    reset_hash: $resetHash
    email_base64: $emailBase64
  )
}
    `;
export type VerifyResetPasswordLinkMutationFn = Apollo.MutationFunction<VerifyResetPasswordLinkMutation, VerifyResetPasswordLinkMutationVariables>;

/**
 * __useVerifyResetPasswordLinkMutation__
 *
 * To run a mutation, you first call `useVerifyResetPasswordLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyResetPasswordLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyResetPasswordLinkMutation, { data, loading, error }] = useVerifyResetPasswordLinkMutation({
 *   variables: {
 *      password: // value for 'password'
 *      resetHash: // value for 'resetHash'
 *      emailBase64: // value for 'emailBase64'
 *   },
 * });
 */
export function useVerifyResetPasswordLinkMutation(baseOptions?: Apollo.MutationHookOptions<VerifyResetPasswordLinkMutation, VerifyResetPasswordLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyResetPasswordLinkMutation, VerifyResetPasswordLinkMutationVariables>(VerifyResetPasswordLinkDocument, options);
      }
export type VerifyResetPasswordLinkMutationHookResult = ReturnType<typeof useVerifyResetPasswordLinkMutation>;
export type VerifyResetPasswordLinkMutationResult = Apollo.MutationResult<VerifyResetPasswordLinkMutation>;
export type VerifyResetPasswordLinkMutationOptions = Apollo.BaseMutationOptions<VerifyResetPasswordLinkMutation, VerifyResetPasswordLinkMutationVariables>;
export const CheckoutDocument = gql`
    mutation checkout($couponCode: String) {
  checkout(coupon_code: $couponCode)
}
    `;
export type CheckoutMutationFn = Apollo.MutationFunction<CheckoutMutation, CheckoutMutationVariables>;

/**
 * __useCheckoutMutation__
 *
 * To run a mutation, you first call `useCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutMutation, { data, loading, error }] = useCheckoutMutation({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useCheckoutMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutMutation, CheckoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckoutMutation, CheckoutMutationVariables>(CheckoutDocument, options);
      }
export type CheckoutMutationHookResult = ReturnType<typeof useCheckoutMutation>;
export type CheckoutMutationResult = Apollo.MutationResult<CheckoutMutation>;
export type CheckoutMutationOptions = Apollo.BaseMutationOptions<CheckoutMutation, CheckoutMutationVariables>;
export const ClearCartDocument = gql`
    mutation ClearCart {
  ClearCart
}
    `;
export type ClearCartMutationFn = Apollo.MutationFunction<ClearCartMutation, ClearCartMutationVariables>;

/**
 * __useClearCartMutation__
 *
 * To run a mutation, you first call `useClearCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearCartMutation, { data, loading, error }] = useClearCartMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearCartMutation(baseOptions?: Apollo.MutationHookOptions<ClearCartMutation, ClearCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearCartMutation, ClearCartMutationVariables>(ClearCartDocument, options);
      }
export type ClearCartMutationHookResult = ReturnType<typeof useClearCartMutation>;
export type ClearCartMutationResult = Apollo.MutationResult<ClearCartMutation>;
export type ClearCartMutationOptions = Apollo.BaseMutationOptions<ClearCartMutation, ClearCartMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  Logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const SetAddressDocument = gql`
    mutation SetAddress($pincode: String!, $state: String!, $city: String!, $address: String!) {
  setAddress(pincode: $pincode, state: $state, city: $city, address: $address)
}
    `;
export type SetAddressMutationFn = Apollo.MutationFunction<SetAddressMutation, SetAddressMutationVariables>;

/**
 * __useSetAddressMutation__
 *
 * To run a mutation, you first call `useSetAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAddressMutation, { data, loading, error }] = useSetAddressMutation({
 *   variables: {
 *      pincode: // value for 'pincode'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useSetAddressMutation(baseOptions?: Apollo.MutationHookOptions<SetAddressMutation, SetAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAddressMutation, SetAddressMutationVariables>(SetAddressDocument, options);
      }
export type SetAddressMutationHookResult = ReturnType<typeof useSetAddressMutation>;
export type SetAddressMutationResult = Apollo.MutationResult<SetAddressMutation>;
export type SetAddressMutationOptions = Apollo.BaseMutationOptions<SetAddressMutation, SetAddressMutationVariables>;
export const GetProShowsDocument = gql`
    query GetProShows {
  getProShows {
    Description
    mobileImage
    Image
    Name
    Position
    Price
    Smail_price
    id
  }
}
    `;

/**
 * __useGetProShowsQuery__
 *
 * To run a query within a React component, call `useGetProShowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProShowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProShowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProShowsQuery(baseOptions?: Apollo.QueryHookOptions<GetProShowsQuery, GetProShowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProShowsQuery, GetProShowsQueryVariables>(GetProShowsDocument, options);
      }
export function useGetProShowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProShowsQuery, GetProShowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProShowsQuery, GetProShowsQueryVariables>(GetProShowsDocument, options);
        }
export function useGetProShowsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProShowsQuery, GetProShowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProShowsQuery, GetProShowsQueryVariables>(GetProShowsDocument, options);
        }
export type GetProShowsQueryHookResult = ReturnType<typeof useGetProShowsQuery>;
export type GetProShowsLazyQueryHookResult = ReturnType<typeof useGetProShowsLazyQuery>;
export type GetProShowsSuspenseQueryHookResult = ReturnType<typeof useGetProShowsSuspenseQuery>;
export type GetProShowsQueryResult = Apollo.QueryResult<GetProShowsQuery, GetProShowsQueryVariables>;
export const GetproshowbyNameDocument = gql`
    query GetproshowbyName($name: String!) {
  getproshowbyName(name: $name) {
    Name
    Position
    Price
    Smail_price
    id
    active
    Image
    mobileImage
    Description
  }
}
    `;

/**
 * __useGetproshowbyNameQuery__
 *
 * To run a query within a React component, call `useGetproshowbyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetproshowbyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetproshowbyNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetproshowbyNameQuery(baseOptions: Apollo.QueryHookOptions<GetproshowbyNameQuery, GetproshowbyNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetproshowbyNameQuery, GetproshowbyNameQueryVariables>(GetproshowbyNameDocument, options);
      }
export function useGetproshowbyNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetproshowbyNameQuery, GetproshowbyNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetproshowbyNameQuery, GetproshowbyNameQueryVariables>(GetproshowbyNameDocument, options);
        }
export function useGetproshowbyNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetproshowbyNameQuery, GetproshowbyNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetproshowbyNameQuery, GetproshowbyNameQueryVariables>(GetproshowbyNameDocument, options);
        }
export type GetproshowbyNameQueryHookResult = ReturnType<typeof useGetproshowbyNameQuery>;
export type GetproshowbyNameLazyQueryHookResult = ReturnType<typeof useGetproshowbyNameLazyQuery>;
export type GetproshowbyNameSuspenseQueryHookResult = ReturnType<typeof useGetproshowbyNameSuspenseQuery>;
export type GetproshowbyNameQueryResult = Apollo.QueryResult<GetproshowbyNameQuery, GetproshowbyNameQueryVariables>;
export const GetCombosByTypeDocument = gql`
    query GetCombosByType($types: [String!]!) {
  getCombosByType(types: $types) {
    id
    mobileImage
    Image
    Name
    customisable
  }
}
    `;

/**
 * __useGetCombosByTypeQuery__
 *
 * To run a query within a React component, call `useGetCombosByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCombosByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCombosByTypeQuery({
 *   variables: {
 *      types: // value for 'types'
 *   },
 * });
 */
export function useGetCombosByTypeQuery(baseOptions: Apollo.QueryHookOptions<GetCombosByTypeQuery, GetCombosByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCombosByTypeQuery, GetCombosByTypeQueryVariables>(GetCombosByTypeDocument, options);
      }
export function useGetCombosByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCombosByTypeQuery, GetCombosByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCombosByTypeQuery, GetCombosByTypeQueryVariables>(GetCombosByTypeDocument, options);
        }
export function useGetCombosByTypeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCombosByTypeQuery, GetCombosByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCombosByTypeQuery, GetCombosByTypeQueryVariables>(GetCombosByTypeDocument, options);
        }
export type GetCombosByTypeQueryHookResult = ReturnType<typeof useGetCombosByTypeQuery>;
export type GetCombosByTypeLazyQueryHookResult = ReturnType<typeof useGetCombosByTypeLazyQuery>;
export type GetCombosByTypeSuspenseQueryHookResult = ReturnType<typeof useGetCombosByTypeSuspenseQuery>;
export type GetCombosByTypeQueryResult = Apollo.QueryResult<GetCombosByTypeQuery, GetCombosByTypeQueryVariables>;
export const GetCartItemsByUserDocument = gql`
    query GetCartItemsByUser {
  GetCartItemsByUser {
    id
    userID
    ItemID
    ItemType
    Quantity
    customization
    Size
  }
}
    `;

/**
 * __useGetCartItemsByUserQuery__
 *
 * To run a query within a React component, call `useGetCartItemsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemsByUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCartItemsByUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCartItemsByUserQuery, GetCartItemsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCartItemsByUserQuery, GetCartItemsByUserQueryVariables>(GetCartItemsByUserDocument, options);
      }
export function useGetCartItemsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCartItemsByUserQuery, GetCartItemsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCartItemsByUserQuery, GetCartItemsByUserQueryVariables>(GetCartItemsByUserDocument, options);
        }
export function useGetCartItemsByUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCartItemsByUserQuery, GetCartItemsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCartItemsByUserQuery, GetCartItemsByUserQueryVariables>(GetCartItemsByUserDocument, options);
        }
export type GetCartItemsByUserQueryHookResult = ReturnType<typeof useGetCartItemsByUserQuery>;
export type GetCartItemsByUserLazyQueryHookResult = ReturnType<typeof useGetCartItemsByUserLazyQuery>;
export type GetCartItemsByUserSuspenseQueryHookResult = ReturnType<typeof useGetCartItemsByUserSuspenseQuery>;
export type GetCartItemsByUserQueryResult = Apollo.QueryResult<GetCartItemsByUserQuery, GetCartItemsByUserQueryVariables>;
export const GetProShowbyIdDocument = gql`
    query GetProShowbyId($id: String!) {
  getProShowbyId(id: $id) {
    Name
    Position
    Price
    Smail_price
    Image
    mobileImage
  }
}
    `;

/**
 * __useGetProShowbyIdQuery__
 *
 * To run a query within a React component, call `useGetProShowbyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProShowbyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProShowbyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProShowbyIdQuery(baseOptions: Apollo.QueryHookOptions<GetProShowbyIdQuery, GetProShowbyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProShowbyIdQuery, GetProShowbyIdQueryVariables>(GetProShowbyIdDocument, options);
      }
export function useGetProShowbyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProShowbyIdQuery, GetProShowbyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProShowbyIdQuery, GetProShowbyIdQueryVariables>(GetProShowbyIdDocument, options);
        }
export function useGetProShowbyIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProShowbyIdQuery, GetProShowbyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProShowbyIdQuery, GetProShowbyIdQueryVariables>(GetProShowbyIdDocument, options);
        }
export type GetProShowbyIdQueryHookResult = ReturnType<typeof useGetProShowbyIdQuery>;
export type GetProShowbyIdLazyQueryHookResult = ReturnType<typeof useGetProShowbyIdLazyQuery>;
export type GetProShowbyIdSuspenseQueryHookResult = ReturnType<typeof useGetProShowbyIdSuspenseQuery>;
export type GetProShowbyIdQueryResult = Apollo.QueryResult<GetProShowbyIdQuery, GetProShowbyIdQueryVariables>;
export const IsItStudentMailDocument = gql`
    query IsItStudentMail {
  IsItStudentMail
}
    `;

/**
 * __useIsItStudentMailQuery__
 *
 * To run a query within a React component, call `useIsItStudentMailQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsItStudentMailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsItStudentMailQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsItStudentMailQuery(baseOptions?: Apollo.QueryHookOptions<IsItStudentMailQuery, IsItStudentMailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsItStudentMailQuery, IsItStudentMailQueryVariables>(IsItStudentMailDocument, options);
      }
export function useIsItStudentMailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsItStudentMailQuery, IsItStudentMailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsItStudentMailQuery, IsItStudentMailQueryVariables>(IsItStudentMailDocument, options);
        }
export function useIsItStudentMailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsItStudentMailQuery, IsItStudentMailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsItStudentMailQuery, IsItStudentMailQueryVariables>(IsItStudentMailDocument, options);
        }
export type IsItStudentMailQueryHookResult = ReturnType<typeof useIsItStudentMailQuery>;
export type IsItStudentMailLazyQueryHookResult = ReturnType<typeof useIsItStudentMailLazyQuery>;
export type IsItStudentMailSuspenseQueryHookResult = ReturnType<typeof useIsItStudentMailSuspenseQuery>;
export type IsItStudentMailQueryResult = Apollo.QueryResult<IsItStudentMailQuery, IsItStudentMailQueryVariables>;
export const IsItObscMailDocument = gql`
    query IsItObscMail {
  IsItObscMail
}
    `;

/**
 * __useIsItObscMailQuery__
 *
 * To run a query within a React component, call `useIsItObscMailQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsItObscMailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsItObscMailQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsItObscMailQuery(baseOptions?: Apollo.QueryHookOptions<IsItObscMailQuery, IsItObscMailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsItObscMailQuery, IsItObscMailQueryVariables>(IsItObscMailDocument, options);
      }
export function useIsItObscMailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsItObscMailQuery, IsItObscMailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsItObscMailQuery, IsItObscMailQueryVariables>(IsItObscMailDocument, options);
        }
export function useIsItObscMailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsItObscMailQuery, IsItObscMailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsItObscMailQuery, IsItObscMailQueryVariables>(IsItObscMailDocument, options);
        }
export type IsItObscMailQueryHookResult = ReturnType<typeof useIsItObscMailQuery>;
export type IsItObscMailLazyQueryHookResult = ReturnType<typeof useIsItObscMailLazyQuery>;
export type IsItObscMailSuspenseQueryHookResult = ReturnType<typeof useIsItObscMailSuspenseQuery>;
export type IsItObscMailQueryResult = Apollo.QueryResult<IsItObscMailQuery, IsItObscMailQueryVariables>;
export const IsAlumMailDocument = gql`
    query IsAlumMail {
  IsAlumMail
}
    `;

/**
 * __useIsAlumMailQuery__
 *
 * To run a query within a React component, call `useIsAlumMailQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAlumMailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAlumMailQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsAlumMailQuery(baseOptions?: Apollo.QueryHookOptions<IsAlumMailQuery, IsAlumMailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsAlumMailQuery, IsAlumMailQueryVariables>(IsAlumMailDocument, options);
      }
export function useIsAlumMailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsAlumMailQuery, IsAlumMailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsAlumMailQuery, IsAlumMailQueryVariables>(IsAlumMailDocument, options);
        }
export function useIsAlumMailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsAlumMailQuery, IsAlumMailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsAlumMailQuery, IsAlumMailQueryVariables>(IsAlumMailDocument, options);
        }
export type IsAlumMailQueryHookResult = ReturnType<typeof useIsAlumMailQuery>;
export type IsAlumMailLazyQueryHookResult = ReturnType<typeof useIsAlumMailLazyQuery>;
export type IsAlumMailSuspenseQueryHookResult = ReturnType<typeof useIsAlumMailSuspenseQuery>;
export type IsAlumMailQueryResult = Apollo.QueryResult<IsAlumMailQuery, IsAlumMailQueryVariables>;
export const GetMerchByIdDocument = gql`
    query GetMerchByID($getMerchByIdId: String!) {
  getMerchByID(id: $getMerchByIdId) {
    id
    Name
    Price
    Image
    Description
    available
    active
    customisable
    open_to
  }
}
    `;

/**
 * __useGetMerchByIdQuery__
 *
 * To run a query within a React component, call `useGetMerchByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchByIdQuery({
 *   variables: {
 *      getMerchByIdId: // value for 'getMerchByIdId'
 *   },
 * });
 */
export function useGetMerchByIdQuery(baseOptions: Apollo.QueryHookOptions<GetMerchByIdQuery, GetMerchByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMerchByIdQuery, GetMerchByIdQueryVariables>(GetMerchByIdDocument, options);
      }
export function useGetMerchByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMerchByIdQuery, GetMerchByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMerchByIdQuery, GetMerchByIdQueryVariables>(GetMerchByIdDocument, options);
        }
export function useGetMerchByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMerchByIdQuery, GetMerchByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMerchByIdQuery, GetMerchByIdQueryVariables>(GetMerchByIdDocument, options);
        }
export type GetMerchByIdQueryHookResult = ReturnType<typeof useGetMerchByIdQuery>;
export type GetMerchByIdLazyQueryHookResult = ReturnType<typeof useGetMerchByIdLazyQuery>;
export type GetMerchByIdSuspenseQueryHookResult = ReturnType<typeof useGetMerchByIdSuspenseQuery>;
export type GetMerchByIdQueryResult = Apollo.QueryResult<GetMerchByIdQuery, GetMerchByIdQueryVariables>;
export const GetAllCombosDocument = gql`
    query GetAllCombos {
  getAllCombos {
    Price
    mobileImage
    Description
    id
    Name
    Image
  }
}
    `;

/**
 * __useGetAllCombosQuery__
 *
 * To run a query within a React component, call `useGetAllCombosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCombosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCombosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCombosQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCombosQuery, GetAllCombosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCombosQuery, GetAllCombosQueryVariables>(GetAllCombosDocument, options);
      }
export function useGetAllCombosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCombosQuery, GetAllCombosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCombosQuery, GetAllCombosQueryVariables>(GetAllCombosDocument, options);
        }
export function useGetAllCombosSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllCombosQuery, GetAllCombosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllCombosQuery, GetAllCombosQueryVariables>(GetAllCombosDocument, options);
        }
export type GetAllCombosQueryHookResult = ReturnType<typeof useGetAllCombosQuery>;
export type GetAllCombosLazyQueryHookResult = ReturnType<typeof useGetAllCombosLazyQuery>;
export type GetAllCombosSuspenseQueryHookResult = ReturnType<typeof useGetAllCombosSuspenseQuery>;
export type GetAllCombosQueryResult = Apollo.QueryResult<GetAllCombosQuery, GetAllCombosQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  GetUser {
    email_id
    address
    city
    state
    pincode
    id
    contact_no
    username
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export function useGetUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetCombobyIdDocument = gql`
    query GetCombobyId($Id: String!) {
  getCombobyId(id: $Id) {
    Description
    Image
    Items
    Name
    Price
    active
    available
    customisable
    id
    mobileImage
    open_to
    type
  }
}
    `;

/**
 * __useGetCombobyIdQuery__
 *
 * To run a query within a React component, call `useGetCombobyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCombobyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCombobyIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useGetCombobyIdQuery(baseOptions: Apollo.QueryHookOptions<GetCombobyIdQuery, GetCombobyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCombobyIdQuery, GetCombobyIdQueryVariables>(GetCombobyIdDocument, options);
      }
export function useGetCombobyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCombobyIdQuery, GetCombobyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCombobyIdQuery, GetCombobyIdQueryVariables>(GetCombobyIdDocument, options);
        }
export function useGetCombobyIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCombobyIdQuery, GetCombobyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCombobyIdQuery, GetCombobyIdQueryVariables>(GetCombobyIdDocument, options);
        }
export type GetCombobyIdQueryHookResult = ReturnType<typeof useGetCombobyIdQuery>;
export type GetCombobyIdLazyQueryHookResult = ReturnType<typeof useGetCombobyIdLazyQuery>;
export type GetCombobyIdSuspenseQueryHookResult = ReturnType<typeof useGetCombobyIdSuspenseQuery>;
export type GetCombobyIdQueryResult = Apollo.QueryResult<GetCombobyIdQuery, GetCombobyIdQueryVariables>;
export const GetAllMerchDocument = gql`
    query GetAllMerch {
  getAllMerch {
    Description
    Image
    Name
    Price
    active
    available
    customisable
    id
    open_to
  }
}
    `;

/**
 * __useGetAllMerchQuery__
 *
 * To run a query within a React component, call `useGetAllMerchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMerchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMerchQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMerchQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMerchQuery, GetAllMerchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMerchQuery, GetAllMerchQueryVariables>(GetAllMerchDocument, options);
      }
export function useGetAllMerchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMerchQuery, GetAllMerchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMerchQuery, GetAllMerchQueryVariables>(GetAllMerchDocument, options);
        }
export function useGetAllMerchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllMerchQuery, GetAllMerchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllMerchQuery, GetAllMerchQueryVariables>(GetAllMerchDocument, options);
        }
export type GetAllMerchQueryHookResult = ReturnType<typeof useGetAllMerchQuery>;
export type GetAllMerchLazyQueryHookResult = ReturnType<typeof useGetAllMerchLazyQuery>;
export type GetAllMerchSuspenseQueryHookResult = ReturnType<typeof useGetAllMerchSuspenseQuery>;
export type GetAllMerchQueryResult = Apollo.QueryResult<GetAllMerchQuery, GetAllMerchQueryVariables>;