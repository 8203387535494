import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddtoCartMutation,
  useGetMerchByIdQuery,
  useIsUserLoggedInMutation,
} from "../../generated/graphql";
import Navbar from "../../components/Navbar/Navbar";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/device/reducers";
import useWindowSize from "../../redux/device/useWindowSize";
import axiosInstance from "../../graphql/axiosConfig";
import Popup from "../../ui-elements/popup/popup";
import MerchSlider from "../../components/MerchSlider/MerchSlider";
import "./Merch.scss"
import ImagePopup from "../../components/ImagePopUps/ImagePopUp";
import { SizeChart } from "../../assets/constants";

const Merch: React.FC = () => {

  const sizes = ["SX","S", "M", "L", "XL","XXL","XXXL"];
  useWindowSize();
  const navigate = useNavigate()
  const { device } = useSelector((state: RootState) => state.windowSize);
  const { id = "" } = useParams<{ id: string }>();
  const [selectedPosition, setSelectedPosition] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<number>(1); // Default quantity set to 1


  

  
  const [addToCartMutation,{data:addData,loading:loadingData}] = useAddtoCartMutation();
  const [isStudent,setIsStudent] = useState(false)
  const [isObsc,setIsObsc] = useState(false)
  const [isUser,setIsUser] = useState(false)
  const [LoginPopUp,setLogInPopUp] = useState(false)
  const [selectedSize,setSelectedSize] = useState("")
  const [customNameError,setCustomNameError] = useState("")
  const [sizeError,setSizeError] = useState("")
  const [customName,setCustomName] = useState("")

  




  const { data:merchData, loading:merchLoading, error:merchError } = useGetMerchByIdQuery({
       variables: {
          getMerchByIdId:id // value for 'id'
       },
    });

  const merchName = merchData?.getMerchByID?.Name
  const merchImages = merchData?.getMerchByID?.Image
  const merchDescription = merchData?.getMerchByID?.Description
  const merchIsCustom = merchData?.getMerchByID?.customisable


  async function IsUserLoggedIn() {
    const response = await axiosInstance.post('',{
      query:`
      mutation Mutation {
        IsUserLoggedIn
      }
      `,
    })
    
    if(!response.data.errors)
      setIsUser(true)
  }
    IsUserLoggedIn()



const userType = async () => {
  let respon = await axiosInstance.post("", {
    query: `
    query Query {
        IsItStudentMail
    }`,});

  setIsStudent(respon.data.data.IsItStudentMail)

  let respon1 = await axiosInstance.post("",{
      query: `
      query Query {
          IsItObscMail
      }`,});

  setIsObsc(respon1.data.data.IsItObscMail)

  let alum_response = await axiosInstance.post("",{
      query: `
      query Query {
          IsAlumMail
      }`,});
  }

  userType()
  

  const handleCustomNameInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomName(event.target.value);
  };
  

  const handlePositionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedSize(event.target.value);
  };

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setQuantity(Number(event.target.value));
  };

  const increaseQuantity = () => {
    setQuantity((prev) => prev + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  const handleAddToCart = async () => {
    IsUserLoggedIn(); // Check if user is logged in

    if (isUser) {
      // Proceed only if the user is logged in
      if (selectedSize == "") {
        setSizeError("Please Select your Size");
        return;
      }
      if (merchData?.getMerchByID?.customisable == "true" &&  customName== "") {
        setCustomNameError("Enter name");
        return;
      }
      try {
        await addToCartMutation({
          variables: {
            quantity: quantity,
            itemId: id,
            itemType: "Merch",
            name: merchData?.getMerchByID?.Name,
            size: selectedSize, // Adjust as needed
            customizable: merchData?.getMerchByID?.customisable == "true"? true:false,
            customization: customName,
          },
        });
        setQuantity(1)
        setSizeError("")
        setCustomNameError("")
        console.log("added to cart")
      } catch (error) {
        alert("Failed to add to cart");
      }
    } else {
    //   setLogInPopUp(true);
      setTimeout(() => {
        navigate("/auth");
      }, 2000);
    }
  };

  return (
    <div className="tickets">
      <Navbar />

      
      {addData?.AddtoCart?<Popup type="success" message='Added to cart'/>:""}
      {LoginPopUp?<Popup type="normal" message='Please Login in to add items to your cart'/> : ""}

      <div className={`merch ${device}`}>
        <div className={`merchImages ${device}`}>
            <MerchSlider images={merchData?.getMerchByID?.Image || [] } id={merchData?.getMerchByID?.id || ""}/>
        </div>
        <div className={`merchDetails ${device}`}>
          <div >
            <div className={`text-[#FF000078]  merch-title ${device}`}>
              {merchName?.toUpperCase()} 
            </div>
            <div className={`text-white merch-desc ${device}`}>
              {merchDescription}
            </div> 
            <div className={`popup-row-merch ${device}`}>
                <ImagePopup imageSrc={SizeChart} linkText={"Size Chart"} />
                <ImagePopup imageSrc={SizeChart} linkText={"Oversized Chart"} />
            </div>
          
            
            
          </div>
          {/* {selectedPositionDetails && ( */}
            <div className={`  ${device}`}>
              <div className="max-w-[22vw]">
                <div className={`merch-price w-[22vw] text-22px flex justify-between  ${device}`}>
                  <div className="highlight w-24">Price</div>
                  <div>:</div>
                  <div className="w-24 flex justify-start">
                    ₹{merchData?.getMerchByID?.Price}{" "}
                  </div>
                </div>
                

                <div className={`merch-quantity w-[22vw] text-22px flex justify-between ${device}`}>
                  <span className="highlight w-24">Quantity </span>
                  <div>:</div>
                  <div className="flex justify-start w-24">
                    <div className="flex  justify-start items-center">
                      <button
                        onClick={decreaseQuantity}
                        className="text-white quantity-button"
                      >
                        -
                      </button>
                      <div className="w-8 flex justify-center"> {quantity}</div>
                      <button
                        onClick={increaseQuantity}
                        className="text-white quantity-button"
                      >
                        +
                      </button>
                    </div>     
                  </div>
                </div>

                <div>
                  <div className={`merch-size w-[22vw] text-22px flex justify-between ${device}`}>
                    <div className="highlight w-24">Size</div>
                    <div>:</div>
                    <div className="w-24">
                      <select
                        id="combo-positions"
                        className={`text-22px bg-black ${device}`}
                        onChange={handlePositionChange}
                      >
                        <option
                          value=""
                          disabled
                          selected
                          className={`text-22px bg-black ${device}`}
                        >
                          Select
                        </option>
                        {sizes.map((size) => (
                          <option value={size} className="combo-option-value">
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                {merchData?.getMerchByID?.customisable == "true" && (<div className={`merch-customization text-white text-22px flex justify-between w-[22vw] ${device}`}>
                  <div className="highlight w-24">Customization</div>
                  <div>:</div>
                  <div className="w-24 flex justify-start bg-black">
                    <input
                      className="w-24 combo-input"
                      type="text"
                      placeholder="Name"
                      onChange={handleCustomNameInput}
                    />
                  </div>
                </div>)}
                </div>
              
              
              </div>
              <div className={`flex merch-buttons justify-between ${device}`}>
                <button
                  className={`see-other-btn ${device}`}
                  onClick={()=>{navigate("/merch")}}
                >
                  View other merch
                </button>
                <button
                  className={`add-to-cart-bt ${device}`}
                  onClick={handleAddToCart}
                >
                  Add to Cart
                </button>
              </div>

              <div className="text-red-700 mt-3 flex justify-center w-full text-center">
              {sizeError!="" ? sizeError:""}
            </div>
            <div className="text-red-700 justify-center w-full text-center">
              {customNameError!=""? customNameError:""}
            </div>
            </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default Merch;


