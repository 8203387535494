import React, { useState } from 'react';
import './AddressFormPopUp.scss';

interface Address {
  city: string;
  state: string;
  zip: string;
  address: string;
}

interface AddressFormPopupProps {
  phone: string;
  email: string;
  address: string;
  city: string;
  pincode: string;
  state: string;
  onClose: () => void;
  onSubmit: (address: Address) => void;
}

const AddressFormPopup: React.FC<AddressFormPopupProps> = ({ phone, email, address, city, pincode, state, onClose, onSubmit }) => {
  const [formData, setFormData] = useState<Address>({
    city: city,
    state: state,
    zip: pincode,
    address: address,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleAutofill = () => {
    setFormData({
      city: "Chennai",
      state: "Tamil Nadu",
      zip: "600036",
      address: "IIT Madras",
    });
  };

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup" onClick={(e) => e.stopPropagation()}>
      <button className="autofill-btn" type="button" onClick={handleAutofill} style={{ marginTop: '10px' }}>
            Pick from Institute
          </button>
        <h2>Or Enter Your Address</h2>
        
        <form onSubmit={handleSubmit}>
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={phone}
            readOnly
          />

          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            name="email"
            value={email}
            readOnly
          />

          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />

          <label htmlFor="city">City:</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />

          <label htmlFor="state">State:</label>
          <input
            type="text"
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
          />

          <label htmlFor="zip">PIN Code:</label>
          <input
            type="number"
            id="zip"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
            required
          />

          

          <div className="flex gap-[10%]">
            <button className="cancel-btn flex-2" type="button" onClick={onClose} style={{ marginTop: '20px' }}>
              Cancel
            </button>
            <button className="submit-btn flex-1" type="submit" style={{ marginTop: '20px' }}>
              Submit
            </button>
          </div>

          
        </form>
      </div>
    </div>
  );
};

export default AddressFormPopup;
