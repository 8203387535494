import React, { useState, useEffect } from "react";
import axiosInstance from "../../graphql/axiosConfig";

interface Items {
  proshows_list: string[];
  position_list: string[];
  merch_list: string[];
}

interface Combo {
  id: string;
  Name: string;
  Description: string;
  Image?: string;
  mobileImage?:string;
  Price: number;
  active: string;
  soldTicketsCount?: number;
  available?: number;
  open_to?: string;
  type?: string;
  items?: Items; // Optional, to be calculated
  customisable: string;
}

interface AddComboPopupProps {
  isVisible: boolean;
  onClose: () => void;
  onAddCombo: (combo: Combo) => void;
}

const AddComboPopup: React.FC<AddComboPopupProps> = ({
  isVisible,
  onClose,
  onAddCombo,
}) => {
  const [newCombo, setNewCombo] = useState<Partial<Combo>>({
    Name: "",
    Price: 0,
    Description: "",
    Image: "",
    mobileImage:"",
    available:0,
    open_to:"",
    customisable:"",
  });
  const [comboType, setComboType] = useState<string>("ticket");
  const [proshowList, setProshowList] = useState<string[]>([
    "pop",
    "edm",
    "rock",
    "choreo",
  ]);
  const [positionList, setPositionList] = useState<string[]>([
    "fanpass",
    "bowl",
    "gallery",
    "gallery+",
    "platinum",
    "gold"
  ]);
  const [merchList, setMerchList] = useState<{ id: string; Name: string }[]>(
    []
  );

 

  const [selectedProshowItems, setSelectedProshowItems] = useState<
    { proshow: string; position: string }[]
  >([]);
  const [selectedMerchItems, setSelectedMerchItems] = useState<string[]>([]);

  useEffect(() => {
    // Fetch merch list when component mounts
    const fetchMerch = async () => {
      const query = `
        query GetAllMerch {
          getAllMerch {
            Name
            id
          }
        }`;

      try {
        const response = await axiosInstance.post("", { query });
        setMerchList(response.data.data.getAllMerch);
      } catch (error) {
      }
    };

    fetchMerch();
  }, []);

  const addCombo = async () => {
    if(newCombo.Name===""|| newCombo.open_to===""||newCombo.type==="" ||newCombo.Price===0||newCombo.available===0||!newCombo.Name||!newCombo.Image){
      alert("fill all details")
      return
    }
    const items: Items = {
      proshows_list: selectedProshowItems.map((item) => item.proshow),
      position_list: selectedProshowItems.map((item) => item.position),
      merch_list: selectedMerchItems || [],
    };

    const mutation = `
  mutation AddCombo($name: String!, $items: itemjson!, $price: Float!, $type: String!, $image: String, $description: String, $openTo: String!, $available: Float!, $mobileImage: String, $customisable: String) {
    AddCombo(name: $name, items: $items, price: $price, type: $type, image: $image, description: $description, open_to: $openTo, available: $available, mobileImage: $mobileImage, customisable: $customisable)
  }`;



  try {
    await axiosInstance.post("", {
      query: mutation,
      variables: {
        name: newCombo.Name, 
        items: items, 
        price: newCombo.Price, 
        type: comboType, 
        image: newCombo.Image, 
        description: newCombo.Description, 
        openTo: newCombo.open_to, 
        available: newCombo.available, 
        mobileImage: newCombo.mobileImage, // Add this line
        customisable:newCombo.customisable
      },
    });
      onAddCombo(newCombo as Combo); 
      alert("Combo added, pls reload")
      onClose(); 
    } catch (error: any) {
      alert("Error adding combo: " + error.message);
    }
  };

  // Add selected proshow-position pair
  const addProshowItem = () => {
    if (
      selectedProshowItems.find(
        (item) => item.proshow === "" || item.position === ""
      )
    ) {
      return; // Prevent adding empty selections
    }
    setSelectedProshowItems([
      ...selectedProshowItems,
      { proshow: "", position: "" },
    ]);
  };

  // Remove a proshow-position pair
  const removeProshowItem = (index: number) => {
    const updatedProshowItems = selectedProshowItems.filter(
      (_, idx) => idx !== index
    );
    setSelectedProshowItems(updatedProshowItems);

  };

  // Add selected merch item
  const addMerchItem = (merch: string) => {
    setSelectedMerchItems([...selectedMerchItems, merch]);
  };

  // Remove merch item
  const removeMerchItem = (index: number) => {
    const updatedMerchItems = selectedMerchItems.filter(
      (_, idx) => idx !== index
    );
    setSelectedMerchItems(updatedMerchItems);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-xl mb-4">Add Combo</h2>
        <div>
          {/* Dropdown for selecting type */}
          <select
            value={comboType}
            onChange={(e) => {
              setComboType(e.target.value);
              setSelectedProshowItems([]);
              setSelectedMerchItems([]);
            }}
            className="border p-2 w-full mb-2"
          >

            <option value="ticket">Ticket</option>

            <option value="merch">Merch</option>
          </select>

          {/* Conditional rendering for tickets */}
          {((comboType === "ticket" ||
            comboType === "common") && (
              <>
                {selectedProshowItems.map((item, index) => (
                  <div key={index} className="flex gap-4 mb-2">
                    <select
                      value={item.proshow}
                      onChange={(e) => {
                        const updatedItems = [...selectedProshowItems];
                        updatedItems[index].proshow = e.target.value;
                        setSelectedProshowItems(updatedItems);
                      }}
                      className="border p-2 w-full"
                    >
                      <option value="">Select Proshow</option>
                      {proshowList.map((proshow) => (
                        <option key={proshow} value={proshow}>
                          {proshow}
                        </option>
                      ))}
                    </select>
                    <select
                      value={item.position}
                      onChange={(e) => {
                        const updatedItems = [...selectedProshowItems];
                        updatedItems[index].position = e.target.value;
                        setSelectedProshowItems(updatedItems);
                      }}
                      className="border p-2 w-full"
                    >
                      <option value="">Select Position</option>
                      {positionList.map((position) => (
                        <option key={position} value={position}>
                          {position}
                        </option>
                      ))}
                    </select>
                    {/* <button
                      onClick={() => removeProshowItem(index)}
                      className="bg-red-500 text-white px-2 py-1 rounded"
                    >
                      Remove
                    </button> */}
                  </div>
                ))}
                <button
                  onClick={addProshowItem}
                  className="bg-green-500 text-white px-4 py-2 mb-2 rounded"
                >
                  Add ProShow
                </button>
              </>
            ))}

          {/* Conditional rendering for merch */}
          {comboType === "merch" && (
            <>
              {selectedMerchItems.map((merch, index) => (
                <div key={index} className="flex justify-between mb-2">
                  <span>{merch}</span>
                  <button
                    onClick={() => removeMerchItem(index)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <select
                onChange={(e) => addMerchItem(e.target.value)}
                className="border p-2 w-full mb-2"
              >
                <option value="">Select Merch</option>
                {merchList.map((merch) => (
                  <option key={merch.id} value={merch.Name}>
                    {merch.Name}
                  </option>
                ))}
              </select>
            </>
          )}

          <input
            type="text"
            placeholder="Name"
            value={newCombo.Name}
            onChange={(e) => setNewCombo({ ...newCombo, Name: e.target.value })}
            className="border p-2 w-full mb-2"
          />
          <select
            value={newCombo.open_to}
              onChange={(e) => setNewCombo({...newCombo,open_to:e.target.value}) }
            className="border p-2 w-full mb-2"
          >
            <option value="" disabled>
              Open to
            </option>
            <option value="smail">Smail</option>
            <option value="alumni">Alumni</option>
            <option value="public">Public</option>
          </select>

          {comboType === "merch" && (<select
            value={newCombo.customisable}
              onChange={(e) => setNewCombo({...newCombo,customisable:e.target.value}) }
            className="border p-2 w-full mb-2"
          >
            <option value="" disabled>
              Customisable
            </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </select>)}

          <input
            type="text"
            placeholder="Image"
            value={newCombo.Image}
            onChange={(e) =>
              setNewCombo({ ...newCombo, Image: e.target.value })
            }
            className="border p-2 w-full mb-2"
          />
          
          <input
            type="text"
            placeholder="Mobile Image"
            value={newCombo.mobileImage}
            onChange={(e) =>
              setNewCombo({ ...newCombo, mobileImage: e.target.value })
            }
            className="border p-2 w-full mb-2"
          />
          <input
            type="number"
            placeholder="Price"
            value={newCombo.Price !== undefined ? newCombo.Price : ""}
            onChange={(e) =>
              setNewCombo({ ...newCombo, Price: parseFloat(e.target.value) })
            }
            className="border p-2 w-full mb-2"
          />
          <input
            type="number"
            placeholder="Available Count"
            value={newCombo.available !== null ? newCombo.available : ""}
            onChange={(e) =>
              setNewCombo({
                ...newCombo,
                available: parseFloat(e.target.value),
              })
            }
            className="border p-2 w-full mb-2"
          />
          

          <textarea
            placeholder="Description"
            value={newCombo.Description}
            onChange={(e) =>
              setNewCombo({ ...newCombo, Description: e.target.value })
            }
            className="border p-2 w-full mb-2"
          />
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={addCombo}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Add Combo
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddComboPopup;
