import React, { useEffect, useState } from 'react';
import './Merchs.scss';
import FancyCarousel from "react-fancy-circular-carousel";
import 'react-fancy-circular-carousel/FancyCarousel.css';
import Navbar from '../../components/Navbar/Navbar';
import { useAddtoCartMutation, useGetAllMerchQuery, useGetCombosByTypeQuery, useIsUserLoggedInMutation } from '../../generated/graphql';
import axiosInstance from "../../graphql/axiosConfig";
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../../redux/device/useWindowSize';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/device/reducers';
import SwiperComponent from '../../components/SwiperComponent/SwiperComponent';

const Merchs = () => {
    useWindowSize();
    const { device } = useSelector((state: RootState) => state.windowSize);
    const sizes = ["SX","S", "M", "L", "XL","XXL","XXXL"];
    const colors = ['#f23277', '#358158', '#efaa29'];
    const [focusElement, setFocusElement] = useState(0);
    const [backgroundColor, setBackgroundColor] = useState(colors[0]);
    
    const [isUserLoggedInMutation, { data: loggedIn }] = useIsUserLoggedInMutation();
    const { data, loading, error } = useGetAllMerchQuery();

    const navigate = useNavigate();
    const { data: merchComboData } = useGetCombosByTypeQuery({
        variables: { types: ["merch"] },
    });

    const [isUser, setIsUser] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [selectedSize, setSelectedSize] = useState("N/A");
    const [addToCartMutation] = useAddtoCartMutation();
    const [images, setImages] = useState<string[]>([]);
    const [info, setInfo] = useState<{ title: string; description: string; price: number; id: string }[]>([]);

    useEffect(() => {
        if (data?.getAllMerch) {
            const fetchedImages = data.getAllMerch.map(item => item.Image[0]);
            const fetchedInfo = data.getAllMerch.map(item => ({
                title: item.Name || "No Title",
                description: item.Description || "",
                price: item.Price,
                id: item.id,
            }));
            setImages(fetchedImages);
            setInfo(fetchedInfo);
        }
    }, [data]);
    
    const data1 = {
        slides: merchComboData?.getCombosByType?.map(combo => ({
            src: combo.Image || "",  // Provide default values if needed
            mobilesrc: combo.mobileImage || "",
            alt: combo.Name || "",
            page: combo.id || "",
            customizable: combo.customisable
        })) || []  // Fallback to an empty array if getCombosByType is null or undefined
    };

    const data2 = {
      slides:data?.getAllMerch?.map(merch => ({
        src:merch.Image[0] ||"",
        mobilesrc: merch.Image[0]||"",
        alt:merch.Name || "",
        page:merch.id || "",
        customizable:merch.customisable,

      })) || [] 
    }
    


    async function IsUserLoggedIn() {
        const response = await axiosInstance.post("", {
            query: `
            mutation Mutation {
                IsUserLoggedIn
            }`,
        });
        if (!response.data.errors) setIsUser(true);
    }
    IsUserLoggedIn();

    return (
        <div 
          className="w-full min-h-screen flex flex-col items-center bg-[#000000e4] pt-40 px-10" 
          style={{ transition: 'background-color 0.5s ease' }}
        >
          <Navbar />
          
          <div className="flex flex-col items-center w-full flex-1">
            {device !== "mobile" && (
  <div className="carousel">
    <h1 className="headline-p">MERCH</h1>
    <div className="merch-combos w-full flex justify-center mt-12">
    <div className="image-grid">
  
  {data2.slides.map((item, index) => (
    <div
      key={index}
      className="grid-item"
      onClick={() => navigate(`/merch/${item.page}`)}
    >
      <div className="image-container">
        <p className="image-text">
          {item.customizable === "true"
            ? "CUSTOMIZABLE"
            : "NOT CUSTOMIZABLE"}
        </p>
        <img
          src={item.src}
          alt={item.alt}
          className="grid-image"
          onError={(e) => {
            (e.target as HTMLImageElement).src = "/placeholder-image.png"; // Fallback image
          }}
        />
      </div>
    </div>
  ))}
</div>
    </div>
  </div>
)}

{device === "mobile" && (
      <div className="merch-combos w-full flex justify-center ">
        <SwiperComponent data={data2.slides} heading='MERCH' />
      </div>
    )}




      

            

            {isUser ? (
  <>
    {device === "mobile" && (
      <div className="merch-combos w-full flex justify-center mt-12">
        <SwiperComponent data={data1.slides} heading='MERCH COMBOS'/>
      </div>
    )}

{device !== "mobile" && (
  <div className="carousel xs:h-[40vh] mt-[13vh]">
    <h1 className="headline-p">MERCH COMBOS</h1>
    <div className="merch-combos w-full flex justify-center mt-12">
    <div className="image-grid">
  {data1.slides.map((item, index) => (
    <div
      key={index}
      className="grid-item"
      onClick={() => navigate(`/merchcombos/${item.page}`)}
    >
      <div className="image-container">
        <p className="image-text">
          {item.customizable === "true"
            ? "CUSTOMIZABLE"
            : "NOT CUSTOMIZABLE"}
        </p>
        <img
          src={item.src}
          alt={item.alt}
          className="grid-image"
          onError={(e) => {
            (e.target as HTMLImageElement).src = "/placeholder-image.png"; // Fallback image
          }}
        />
      </div>
    </div>
  ))}
</div>
    </div>
  </div>
)}



  </>
) : (
  <div>
  <h1 className="headline-swiper mt-2 mb-2">MERCH COMBOS</h1>
  <div className="w-[70vw] h-[70%] mt-[10vh] mb-[10vh]  text-white font-bold text-xl font-urbanist py-4 px-6 rounded-lg shadow-lg bg-white bg-opacity-10 flex justify-center items-center">
    Please Login to View Merch Combos
  </div>
  </div>
)}

          </div>
      
          {/* Bottom Spacing Fix */}
          <div className="h-0" />
        </div>
      );
      
};

export default Merchs;
