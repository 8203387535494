export const SAARANG_LOGO = 'https://iili.io/Jnz75x9.png';
export const SAARANG_LOGO_NO_Text = 'https://iili.io/JT7J0og.png';
export const SAARANG_LOGO_WHITE_Text = 'https://iili.io/Jo1aQXs.png';
export const GALLERY_ICON = 'https://iili.io/JouQosp.png';
export const BOWL_ICON = 'https://iili.io/Jou08UG.png';
export const FANPASS_ICON = ' https://iili.io/Jou1zXI.png'
export const MINUS_ICON = 'https://iili.io/JoAf0Av.png';
export const PLUS_ICON = 'https://iili.io/JoAf4f9.png';
export const BACKGROUND_POSTER = 'https://iili.io/JoAalEu.webp';
export const Oatlayout = 'https://iili.io/Jo13Xje.png';
export const SizeChart = 'https://saarang2024.s3.ap-south-1.amazonaws.com/SIZE+CHART.pdf';