import React, { useEffect, useState } from "react";
import "./MerchSlider.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/device/reducers";
import { useGetAllMerchQuery } from "../../generated/graphql";
import { useNavigate } from "react-router-dom";

interface ImageSliderProps {
  images: string[];
  id: string;
}

const MerchSlider: React.FC<ImageSliderProps> = ({ images, id }) => {
  const { device } = useSelector((state: RootState) => state.windowSize);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Track the current slide
  const [thumbnailItems, setThumbnailItems] = useState<{ id: string; image: string }[]>([]);
  const { data, loading, error } = useGetAllMerchQuery();
  const navigate = useNavigate();

  // Handle navigation to next or previous slide
  const handleNextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  // Update thumbnails when GraphQL data is fetched
  useEffect(() => {
    if (data?.getAllMerch) {
      const fetchedItems = data.getAllMerch.map((item) => ({
        id: item.id,
        image: item.Image[0],
      }));
      const filteredItems = fetchedItems.filter((item) => item.id !== id);
      setThumbnailItems(filteredItems.slice(0, 3)); // Limit to 3 thumbnails
    }
  }, [data, id]);

  const handleThumbnailClick = (clickedId: string) => {
    navigate(`/merch/${clickedId}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading merchandise</div>;

  return (
    <div className={`slider-container ${device}`}>
      {/* Main Image Slider */}
      <div className={`main-image-slider ${device}`}>
        {images.length >1 && (<button className="slider-btn prev-btn" onClick={handlePrevSlide}>
          &lt;
        </button>)}
        <img src={images[currentImageIndex]} alt={`Slide ${currentImageIndex}`} />
        {images.length >1 && (<button className="slider-btn next-btn" onClick={handleNextSlide}>
          &gt;
        </button>)}
      </div>

      <div className={`thumbnail-heading ${device}`}>
      Checkout Other Merch
      </div>

      {/* Thumbnails */}
      <div className={`thumbnails ${device}`}>
      
        
        {thumbnailItems.map((item) => (
          <img
            key={item.id}
            src={item.image}
            alt={`Thumbnail ${item.id}`}
            className={`thumbnail ${device}`}
            onClick={() => handleThumbnailClick(item.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default MerchSlider;
