import React, { useState } from "react";
import "./SwiperComponent.scss";
import { useNavigate } from "react-router-dom";

interface CardData {
  src: string;
  mobilesrc: string;
  alt: string;
  page: string;
  customizable:string | null |undefined;
}

interface SliderProps {
  data: CardData[];
  heading:string;
}

const SwiperComponent: React.FC<SliderProps> = ({ data,heading }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = data.length;
  const navigate = useNavigate();

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
  };

  const nav = heading === 'MERCH' ? "merch":"merchcombos"

  return (
    <div className="relative w-full">
      {/* Slider container */}
      <h1 className="headline-swiper">{heading}</h1>
      <div className="overflow-hidden">
        <div
          className="flex transition-all duration-500"
          style={{ transform: `translateX(-${currentIndex * 80}vw)` }}
        >
          {data.map((item, index) => (
            <div key={index} className="flex-shrink-0 w-[80vw] h-auto p-2">
              <div className="relative bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="relative">
                  {/* Text at the top */}
                  <p className="image-text absolute top-0 left-0 w-full bg-black bg-opacity-60 text-white text-center font-bold p-2 z-10">
                    {item.customizable === "true"
                      ? "CUSTOMIZABLE"
                      : "NOT CUSTOMIZABLE"}
                  </p>
                  {/* Image */}
                  <img
                    src={item.src}
                    alt={item.alt}
                    className="w-full h-auto object-cover"
                    onClick={() => {
                      navigate(`/${nav}/${item.page}`);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Navigation buttons */}
      <div className="flex flex-row justify-center gap-4 mt-3 mb-3">
        <button
          onClick={prevSlide}
          className="bg-red-700 w-[90px] text-white p-2 rounded-lg"
        >
          Prev
        </button>
        <button
          onClick={nextSlide}
          className="bg-red-700 w-[90px] text-white p-2 rounded-lg"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SwiperComponent;
