import { ApolloClient, InMemoryCache } from "@apollo/client";


const nodeEnv = process.env.NODE_ENV || "production";

const api_url = nodeEnv === "production" 
  ? "https://api.sales.saarang.org/graphql" 
  : "http://localhost:8000/graphql";

// const api_url = "http://localhost:8000/graphql";


const client = new ApolloClient({
  uri: api_url,
  // uri: "https://api.sales.saarang.org/graphql",
  cache: new InMemoryCache(),
  credentials: "include",
});

export default client;




