import React, { useState, useEffect } from "react";
import axiosInstance from "../../graphql/axiosConfig";

interface Merch {
  Description: string;
  Image: string | string[];
  Name: string;
  Price: number;
  open_to: string | null;
  id: string;
  active: string | null;
  available: number;
  soldQuantity?: number;
}

interface EditMerchPopupProps {
  onClose: () => void;
  isVisible: boolean;
  merch: Merch;
  onMerchEdit: (updatedMerch: Partial<Merch>) => void;
}

const EditMerchPopup: React.FC<EditMerchPopupProps> = ({
  onClose,
  merch,
  isVisible,
  onMerchEdit,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
//   const [image, setImage] = useState("");
  const [images, setImages] = useState<string[]>([]);
  const [price, setPrice] = useState<number>();
  const [available, setAvailable] = useState<number>();

  useEffect(() => {
    if (merch) {
      setName(merch.Name || "");
      setDescription(merch.Description || "");
    //   setImage("");
      setImages(Array.isArray(merch.Image) ? merch.Image : []);
      setPrice(merch.Price);
      setAvailable(merch.available);
    }
  }, [merch]);

  const handleAddImage = () => {
    setImages([...images, ""]);
  };

  const handleRemoveImage = (index: number) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleImageChange = (index: number, value: string) => {
    const updatedImages = [...images];
    updatedImages[index] = value;
    setImages(updatedImages);
  };

  const handleEditMerch = async () => {
    const mutation = `
      mutation EditMerch($editMerchId: String!, $available: Float, $image: [String!], $price: Float, $description: String, $newName: String) {
        EditMerch(id: $editMerchId, available: $available, image: $image, price: $price, description: $description, new_name: $newName)
      }
    `;
  
    try {
      await axiosInstance.post("", {
        query: mutation,
        variables: {
          editMerchId: merch.id,
          newName: name,
          description,
          price,
          image: images, // Use images instead of image
          available,
        },
      });
      alert("Merch updated successfully!");
      onMerchEdit({
        Name: name,
        Description: description,
        Image: images, // Update the parent component with the correct images
        Price: price,
      });
      onClose(); // Close the popup after successful edit
    } catch (error: any) {
      alert(`Error updating merch: ${error.message}`);
    }
  };
  

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 overflow-y-auto">
      <div className="bg-white p-6 rounded-lg w-[60%] max-h-[80vh] overflow-y-auto">
        <h2 className="text-xl mb-4">Edit Merch</h2>
        <div className="mb-4">
          <label className="block mb-2">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Description:</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        {/* <div className="mb-4">
          <label className="block mb-2">Image URL:</label>
          <input
            type="text"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            className="border p-2 w-full"
          />
        </div> */}
        <div className="mb-4">
          <label className="block mb-2">Image URLs:</label>
          {images.map((url, index) => (
            <div key={index} className="flex gap-2 mb-2">
              <input
                type="text"
                value={url}
                onChange={(e) => handleImageChange(index, e.target.value)}
                className="border p-2 flex-1"
              />
              <button
                onClick={() => handleRemoveImage(index)}
                className="bg-red-500 text-white px-2 py-1 rounded"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            onClick={handleAddImage}
            className="bg-green-500 text-white px-4 py-2 rounded mt-2"
          >
            Add Image
          </button>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Price:</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
            className="border p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Available:</label>
          <input
            type="number"
            value={available}
            onChange={(e) => setAvailable(Number(e.target.value))}
            className="border p-2 w-full"
          />
        </div>
        <div className="flex justify-end gap-5">
          <button
            onClick={handleEditMerch}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="bg-gray-400 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
  
};

export default EditMerchPopup;
