import React, { useEffect, useState, useCallback } from "react";
import './cart.scss';
import useRazorpay from "react-razorpay";
import * as constants from "../../assets/constants";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';


import {
  useAddtoCartMutation,
  useCheckoutMutation,
  useClearCartMutation,
  useGetAllCombosQuery,
  useGetCartItemsByUserLazyQuery,
  useGetCartItemsByUserQuery,
  useGetMerchByIdLazyQuery,
  useGetProShowbyIdLazyQuery,
  useGetUserQuery,
  useIsAlumMailLazyQuery,
  useIsItObscMailLazyQuery,
  useIsItStudentMailLazyQuery,
  useIsItStudentMailQuery,
  useIsUserLoggedInMutation,
  useRemoveFromCartMutation,
  useSetAddressMutation,
} from "../../generated/graphql";
import Navbar from "../../components/Navbar/Navbar";
import axiosInstance from "../../graphql/axiosConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/device/reducers";
import useWindowSize from "../../redux/device/useWindowSize";
import { dividerClasses } from "@mui/material";
import Popup from "../../ui-elements/popup/popup";
import AddressFormPopup from "../../components/AddressFormPopUp/AddressFormPopUp";

class TicketObject {
  id: string;
  name: string;
  position: string;
  quantity: number;
  price: number;
  image:string;
  ItemType:string;

  constructor(
    id: string,
    name: string,
    position: string,
    quantity: number,
    price: number,
    image:string,
    ItemType:string
  ) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.quantity = quantity;
    this.price = price;
    this.image = image;
    this.ItemType = ItemType
  }
}


const Cart: React.FC = () => {
  useWindowSize()
  const { device } = useSelector((state: RootState) => state.windowSize);
  
  const [isUserLoggedInMutation, { data: loggedIn }] =
    useIsUserLoggedInMutation();
  const {
    data: user,
    error: userError,
    loading: userLoading,
  } = useGetUserQuery();
  const [
    checkout,
    { data: checkoutData, error: checkoutError, loading: checkoutLoading },
  ] = useCheckoutMutation();
  const [removeFromCartMutation, { data: removeFromCartData }] =
    useRemoveFromCartMutation();
  const [setAddressMutation, { data:addressdata, loading:addressloading, error:addresserror }] = useSetAddressMutation();
  const [clearCartMutation] = useClearCartMutation();
  const [getCartItemsQuery,
    {data: cartItemsData,
    loading: cartItemsLoading,
    error: cartItemsError}
  ]= useGetCartItemsByUserLazyQuery();
  const [getProShowbyId, { data: proShowbyIdData }] =
    useGetProShowbyIdLazyQuery();
  const [getMerchbyId, { data: merchbyIdData }] = useGetMerchByIdLazyQuery();
  const {
    data: allCombosData,
    error: allCombosError,
    loading: allCombosLoading,
  } = useGetAllCombosQuery();

  const [IsItStudentMail, { data: IsItStudentMailData }] =
    useIsItStudentMailLazyQuery();
  const [IsItObscMail, { data: IsItObscMailData }] = useIsItObscMailLazyQuery();
  const [IsItAlumMail, { data: IsItAlumMailData }] = useIsAlumMailLazyQuery();
  const [addToCartMutation] = useAddtoCartMutation();

  const [tickets_table, setTicketstable] = useState<TicketObject[]>([]);
  const [merch_table, setMerchtable] = useState<any[]>([]);

  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();
  const [success, setSuccess] = useState("");
  const [error1, setError1] = useState("");
  const [error, setError] = useState("");
  const [coupon, setCoupon] = useState("");
  const [subTotalPrice,setSubTotalPrice] = useState(0)
  const [agreeToTerms,setAgreeToTerms] = useState(false)
  const [showPopup, setShowPopup] = useState(false);
  const [addressAddedSuccess,setAddressAddedSuccess] = useState(false)
  const [cartHasMerch,setCartHasMerch] = useState(false)
  const [merchItemsNum,setMerchItemsNum] = useState<number>(0)
  const [deliveryFees,setDeliveryFees] = useState(0)
  const [userPincode,setUserPincode] = useState("")
  const [isUser, setIsUser] = useState(false);

  

  const handleClosePopup = () => setShowPopup(false);

  const handleFormSubmit = async ({ city, state, zip, address }: { city: string; state: string; zip: string; address: string }) => {
    try{
      await setAddressMutation({
        variables: {
          city:city,
          state:state,
          pincode:zip,
          address:address
      }})
      console.log("address added")
      setAddressAddedSuccess(true);
      window.location.reload()
      setTimeout(() => {
        setAddressAddedSuccess(false)
      }, 3000);
    }
    catch(err){
      console.log("Unable to add address!")
    }

    setShowPopup(false)
  };
  
  
  

 

  const handlePayment = useCallback(
    (
      orderId: string,
      price: string,
      email: string | undefined,
      name: string | undefined,
      phone: string | undefined
    ) => {
      const options = {
        key: "rzp_live_VgVO9uzPsxViN5",
        amount: price,
        currency: "INR",
        name: "Saarang 2025",
        description: "Payment Transaction",
        image: constants.SAARANG_LOGO,
        notes: {
          type: "Saarang Sales 2025", 
         
      },
        payment_capture: "true",
        order_id: orderId,

        handler: (res: any) => {
          navigate("/myorders")
        },

        prefill: {
          name: name,
          email: email,
          contact: phone,
        },
      
        theme: {
          color: "#FF0000",
        },
        modal: {
          ondismiss: function () {
            setSuccess("");
            setError1("You have closed Razorpay window, payment failed");
            window.location.reload(); // Redirect to home page
          },
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();

    },
    [Razorpay]
  );

  async function Checkout() {
    // Uncomment the following lines if you want to check for address
    if (user?.GetUser.address === null || user?.GetUser.address === "") {
        setShowPopup(true)
        return
    }

    if (subTotalPrice > 0) {
        try {
            // Send the checkout mutation
            const response = await axiosInstance.post("", {
                query: `
                    mutation Checkout {
                        checkout
                    }
                `,
            });

            // Check for errors in the response
            if (response.data.errors) {
                setError1(response.data.errors[0].message);
                setTimeout(() => {
                  setError1(""); 
              }, 3000);
                return; 
            }

            
            const response_arr = response.data.data.checkout.split(" ");
            if (response_arr) {
                const order_id = response_arr[0];
                const price = response_arr[1];

                // Handle payment with the retrieved order ID and price
                handlePayment(
                    order_id,
                    price,
                    user?.GetUser.email_id,
                    user?.GetUser.username,
                    user?.GetUser.contact_no
                );
            }
            
        } catch (error) {
            // Handle any errors that occurred during the request
            // setError1(response.data.);
            console.error('Checkout error:', error);
        }
    } else {
        setError1("Please Add Items to your cart");
        setTimeout(() => {
          setError1(""); 
      }, 3000);
    }
}

  async function clearCart() {
    const response = await clearCartMutation();
    setCartHasMerch(false)
    window.location.reload();
  }

  
  const handleAddToCart = async (item: TicketObject) => {
    const { data: isLoggedInData } = await isUserLoggedInMutation(); // Check if user is logged in
    if (isLoggedInData?.IsUserLoggedIn) {
      // Proceed only if the user is logged in
        try {
          await removeFromCartMutation({
            variables: {
              quantity: -1,
              itemId: item.id,
              itemType: item.ItemType,
              size:item.position,
            },
          });

          setTicketstable((prev) =>
            prev.map((cartItem) =>
              cartItem.id === item.id && cartItem.ItemType === item.ItemType && cartItem.position === item.position
                ? { ...cartItem, quantity: cartItem.quantity + 1 }
                : cartItem
            ));
            let tPrice;

            if(item.ItemType === "Merch" || item.ItemType === "MerchCombo"){
              let count = merchItemsNum
              count = count+1
              await setDeliveryFees(50+(count -1)*10)
              setMerchItemsNum(count)
            }

            


            if (item.ItemType === "Combo") {
              const newQuantity = item.quantity + 1; 
  
              
              const currentComboTotal = item.price * item.quantity * (item.quantity<5?1:0.95);
              tPrice = subTotalPrice - currentComboTotal; 
  
              
              const newDiscountMultiplier = newQuantity < 5 ? 1 : 0.95;
              const newComboTotal = item.price * newQuantity * newDiscountMultiplier; 
              tPrice += newComboTotal; 
          } else {
              
              tPrice = subTotalPrice + item.price; 
          }
            setSubTotalPrice(tPrice)
        } catch (error) {
          alert("Failed to add to cart");
        }
    } else {
      alert("Please log in to add items to your cart.");
    }
  };

  const handleRemoveFromCart = async (item:TicketObject) => {
    const { data: isLoggedInData } = await isUserLoggedInMutation(); // Check if user is logged in

    if (isLoggedInData?.IsUserLoggedIn) {
      // Proceed only if the user is logged in
      if(item.quantity>1){

        try {
          await removeFromCartMutation({
            variables: {
              quantity: 1,
              itemId: item.id,
              itemType: item.ItemType,
              size:item.position
            },
          });
          setTicketstable((prev) =>
            prev.map((cartItem) =>
              cartItem.id === item.id && cartItem.ItemType === item.ItemType && cartItem.position === item.position
                ? { ...cartItem, quantity: cartItem.quantity - 1 }
                : cartItem
            ));
            let tPrice;
            if(item.ItemType === "Merch" || item.ItemType === "MerchCombo"){
              let count = merchItemsNum
              count = count-1
              await setDeliveryFees(50+(count -1)*10)
              setMerchItemsNum(count)
            }
        if (item.ItemType === "Combo") {
            const newQuantity = item.quantity - 1; 

            
            const currentComboTotal = item.price * item.quantity * (item.quantity<5?1:0.95);
            tPrice = subTotalPrice - currentComboTotal; 

            
            const newDiscountMultiplier = newQuantity < 5 ? 1 : 0.95;
            const newComboTotal = item.price * newQuantity * newDiscountMultiplier; 
            tPrice += newComboTotal; 
        } else {
            
            tPrice = subTotalPrice - item.price; 
        }
            setSubTotalPrice(tPrice)
            
          
          
        } catch (error) {
          alert("Failed to remove from cart");
        }
      }
    } else {
      if(item)
      alert("Please log in to remove items from your cart.");
    }
  };

  

  
  // getCartItems();

  async function  outerGetCartItems(){
    const getCartItems = async () => {
      const response = await axiosInstance.post('', {
        query:`
        query GetCartItemsByUser {
          GetCartItemsByUser {
            id
            userID
            ItemID
            ItemType
            Quantity
            customization
            Size
          }
        }
        `,
      }
      );
  
      let cart_items = response.data.data.GetCartItemsByUser;
      var ticket_items: any[] = [];
      var merch_items: any[] = [];

      

      
      let merchitems = 0
      console.log(cart_items)
      for (var i = 0; i < cart_items.length; i++) {
        
        if (cart_items[i].ItemType === "ProShow") {
          
          const response = await axiosInstance.post('', {
            query:`query GetProShowbyId($id: String!) {
              getProShowbyId(id: $id) {
                Name
                Position
                Price
                Smail_price
                Image
              }
            }
            `,
            variables:{
              "id": cart_items[i].ItemID
            }
          }
          );
          
  
          let ticket_details = response.data.data.getProShowbyId;
          let respon = await axiosInstance.post("", {
            query: `
            query Query {
                IsItStudentMail
              }`,});
  
          if (ticket_details) {
            if (respon.data.data.IsItStudentMail) {
              ticket_items.push(
                new TicketObject(
                  cart_items[i].ItemID,
                  ticket_details.Name,
                  ticket_details.Position,
                  cart_items[i].Quantity,
                  ticket_details.Smail_price ?? 0,
                  ticket_details.Image,
                  cart_items[i].ItemType
                )
              );
            } else
              ticket_items.push(
                new TicketObject(
                  cart_items[i].ItemID,
                  ticket_details.Name,
                  ticket_details.Position,
                  cart_items[i].Quantity,
                  ticket_details.Price,
                  ticket_details.Image,
                  cart_items[i].ItemType
                )
              );
          }
        } else if (cart_items[i].ItemType === "Merch") {
          // merch_items.push(cart_items[i]);

          setCartHasMerch(true)
          merchitems = merchitems + cart_items[i].Quantity
          console.log(merchitems)
          await setDeliveryFees(50+(merchitems -1)*10)
          await setMerchItemsNum(merchitems)
          
          
      
          const response = await axiosInstance.post('', {
            query:`query GetMerchByID($getMerchByIdId: String!) {
              getMerchByID(id: $getMerchByIdId) {
                id
                Name
                Price
                Image
                Description
                active
              }
            }
            `,
            variables:{
              "getMerchByIdId": cart_items[i].ItemID
            }
          }
          );
  
          let merch_details = response.data.data.getMerchByID;
          let respon = await axiosInstance.post("", {
            query: `
            query Query {
                IsItStudentMail
              }`,});
          let final_desc = cart_items[i].Size;
  
          if (!(cart_items[i].customization === null)) {
            final_desc = cart_items[i].Size + " - " + cart_items[i].customization;
          }
          if (merch_details && final_desc) {

              ticket_items.push(
                
                new TicketObject(
                  cart_items[i].ItemID,
                  merch_details.Name,
                  final_desc,
                  cart_items[i].Quantity,
                  merch_details.Price,
                  merch_details.Image[0],
                  cart_items[i].ItemType
                )
              );
              console.log("pushed")
          }
        } else if (cart_items[i].ItemType === "Combo" || cart_items[i].ItemType === "MerchCombo") {

          if(cart_items[i].ItemType === "MerchCombo"){

            setCartHasMerch(true)
            merchitems = merchitems + cart_items[i].Quantity
            console.log(merchitems)
            await setDeliveryFees(50+(merchitems -1)*10)
            await setMerchItemsNum(merchitems)

          }
          
          const response = await axiosInstance.post("", {
            query: `query GetAllCombos {
            getAllCombos {
              Price
              Description
              id
              Name
              Image
            }
          }
          `,
          });
          let all_combos = response.data.data.getAllCombos;
          let ind = -1;
          for (let t = 0; t < all_combos.length; t++) {
            if (all_combos[t].id === cart_items[i].ItemID) {
              ind = t;
              break;
            }
          }
         

          let customization: string;

          
          if (cart_items[i].customization === null) {
            console.log("Hello")
            if (cart_items[i].Size !== "") {
              customization = cart_items[i].Size;
            } else {
              customization = "N/A";
            }
          } else {
            customization =
              cart_items[i].Size + "-" + cart_items[i].customization ;
          }

          
            ticket_items.push(
              new TicketObject(
                cart_items[i].ItemID,
                all_combos[ind].Name,
                customization,
                cart_items[i].Quantity,
                all_combos[ind].Price,
                all_combos[ind].Image,
                cart_items[i].ItemType
              )
            );
          // }
        }
      
      }
  
      setTicketstable(ticket_items);
         await setMerchtable(merch_items); 

      let tPrice = 0


      for (var i = 0; i < ticket_items.length; i++) {
          const ticketItem = ticket_items[i];
          if (ticketItem.ItemType === "Combo") {
              // Apply discount logic for Combos
              const discountMultiplier = ticketItem.quantity < 5 ? 1 :0.95;
              tPrice += ticketItem.price * ticketItem.quantity * discountMultiplier;
          } else {
              // Regular price calculation for other item types
              tPrice += ticketItem.price * ticketItem.quantity;
          }
      }
      
      setSubTotalPrice(tPrice);
      
  
  
  
    }
    await getCartItems();
  }
  
    useEffect(() => {
      outerGetCartItems();
    }, []);

    const handleCheckboxChange = (e:React.ChangeEvent<HTMLInputElement>) => {
      setAgreeToTerms(e.target.checked);
    }


    async function handleDelete(item: TicketObject) {
      try {
        const ty = item.ItemType
        const quan = item.quantity
        const response = await removeFromCartMutation({
          variables: {
            quantity: item.quantity,
            itemId: item.id,
            itemType: item.ItemType,
            size:item.position,
            name: "N/A",
          },
        });
        if (response?.data?.RemoveFromCart === "Success") {
          // window.location.reload();
          if(item.ItemType === "Merch" || item.ItemType === "MerchCombo"){
            if(quan === merchItemsNum){
              setCartHasMerch(false)
              setDeliveryFees(0)
            }
            setMerchItemsNum(merchItemsNum-quan)
          }
          
          outerGetCartItems()
         
        }
      } catch (error) {
      }
    }
    async function IsUserLoggedIn() {
      const response = await axiosInstance.post("", {
          query: `
          mutation Mutation {
              IsUserLoggedIn
          }`,
      });
      if (!response.data.errors) setIsUser(true);
  }
  IsUserLoggedIn();
  console.log(isUser)
  
  return (
    <div className=" Cart-Container relative flex-col justify-between w-full h-screen bg-black ">
      <Navbar/>
      {/* {?<Popup type="loading" message={""}/>:""} */}

      <div className="banner-cart">
        <div className={`banner-scrolling-text-cart`}>
          Faculty and residents of IIT Madras can purchase their tickets through the e-services portal. You will receive the details via email soon.
        </div>
      </div>
      {error1!=="" ? <Popup message={error1} type="fails"/>:null}
      {showPopup && (
  <AddressFormPopup
    email={user?.GetUser ? user.GetUser.email_id : ""}
    phone={user?.GetUser?.contact_no || ""}
    address={user?.GetUser?.address || ""}
    city={user?.GetUser?.city || ""}
    state={user?.GetUser?.state || ""}
    pincode={user?.GetUser?.pincode || ""}
    onClose={handleClosePopup}
    onSubmit={handleFormSubmit}
  />
)}

    {addressAddedSuccess && <Popup message={"Address Added Successfully"} type={"success"}/>}
      
      <div className={`Cart-Section-Outer ${device}`}>
      
      <div className="Cart-Section">
        <div className={`Cart-Elements ${device}`}>
        {isUser && (
  <div
    className={`About w-full text-2xl height-full ${device} grid gap-y-4 p-4`}
  >
    {/* Desktop Layout */}
    <div 
  className="hidden md:grid grid-cols-[1fr_2fr_1fr_2fr] gap-x-4 items-center w-full"
>
  <div className="font-bold">Name</div>
  <div>: {user?.GetUser.username}</div>
  <div className="font-bold">Phone</div>
  <div>: {user?.GetUser.contact_no}</div>
  <div className="font-bold">Mail</div>
  <div className="truncate">: {user?.GetUser.email_id}</div>
  {user?.GetUser.pincode && ( <div className="font-bold">PIN code</div>)}
  {user?.GetUser.pincode && ( <div>: {user?.GetUser.pincode}</div>)}
</div>
    {/* Mobile Layout */}
    <div 
  className="grid md:hidden grid-cols-[1fr_2fr] gap-y-[2.5px] w-full"
>
  <div className="font-bold">Name</div>
  <div>: {user?.GetUser.username}</div>
  <div className="font-bold">Phone</div>
  <div>: {user?.GetUser.contact_no}</div>
  <div className="font-bold">Mail</div>
  <div className="truncate">: {user?.GetUser.email_id}</div>
  {user?.GetUser.pincode && (<div className="font-bold">PIN code</div>)}
  {user?.GetUser.pincode && (<div>: {user?.GetUser.pincode}</div>)}
</div>
  </div>
)}




        {tickets_table.length === 0 && (<div className="Cart-Element  width-full text-center text-2xl height-full justify-center">Your Cart is Empty</div>)}      
        {tickets_table.map((item, index) => (
          <div className={`Cart-Element ${device}`}>
            <div className={`Cart-Element-Img ${device}`}>
              <img src={item.image} className="Cart-Element-Image" alt="" />
            </div>
          <div className={`Cart-Element-Details ${device}`}>
            <div className="flex flex-row">
              <div className={`Cart-Element-Data-Value ${device}`}>Item</div>
                <div className="mx-3">:</div>
              <div className="w-28 flex justify-start mr-5">{item.name}</div>
          </div>
    <div className="flex flex-row">
      <div className={`Cart-Element-Data-Value ${device}`}>{item.ItemType =="ProShow"?"Ticket":"Size"}</div>
        <div className="mx-3">:</div>
      <div className="w-28 flex justify-start mr-5">{item.position}</div>
    </div>
    <div className="flex flex-row">
                  <span className={`Cart-Element-Data-Value ${device}`}>Quantity </span>
                  <div className="mx-3">:</div>
                  <div className="flex justify-start w-28">
                    <div className="flex  justify-start items-center">
                      <button
                        onClick={() => { handleRemoveFromCart(item) }}
                        className="text-white Cart-Element-Button"
                      >
                        -
                      </button>
                      <div className="w-8 flex justify-center"> {item.quantity}</div>
                      <button
                        onClick={() => { handleAddToCart(item) }}
                        className="text-white Cart-Element-Button"
                      >
                        +
                      </button>
                    </div>     
                  </div>
                </div>
                <div className="flex flex-row">
      <div className={`Cart-Element-Data-Value ${device}`}>Price</div>
        <div className="mx-3">:</div>
      <div className="w-28 flex justify-start mr-5">₹{item.price}</div>
    </div>
  </div>
  <div className={`Cart-Element-Delete-Button cursor-pointer ${device}`} onClick={() => handleDelete(item)}>
    <DeleteSharpIcon />
  </div>
</div>


))}
<div className="flex w-full justify-around">

        
        {!(tickets_table.length=== 0) && (<div className="w-[50%] flex justify-center  mt-4 "><button className={`Clear-Cart-Button ${device}`} onClick={clearCart}>
          Clear Cart
        </button></div>)}
</div>
</div>

      </div>
      
      <div className={`Total-Container ${device}`}>
        {}

        <h2 className={`Total-Heading ${device}`}>TOTAL</h2>
        <div className="">

        <div className="flex flex-row justify-center ">
          <div className="w-28 ml-12">Sub Total</div>
            <div className="mx-3">:</div>
          <div className="w-28 flex justify-start ">₹{subTotalPrice.toFixed(2)}</div>
        </div>

        {merchItemsNum>0 && user?.GetUser.pincode!=="600036"  && (<div className="flex flex-row justify-center ">
          <div className="w-28 ml-12">Delivery fees</div>
            <div className="mx-3">:</div>
          <div className="w-28 flex justify-start ">₹{(deliveryFees).toFixed(2)}</div>
        </div>)}

        <div className="flex flex-row justify-center">
          <div className="w-28 ml-12 text-xm">Razorpay fee</div>
            <div className="mx-3">:</div>
          <div className="w-28 flex justify-start ">₹{user?.GetUser.pincode !== "600036" ? (0.02*(subTotalPrice+deliveryFees)).toFixed(2):(0.02*(subTotalPrice)).toFixed(2)}</div>
        </div>
        <hr className="border-t-2 border-white-400 my-2 w-9/12 mx-auto" />
        <div className="flex flex-row justify-center">
          <div className="w-28 ml-12 font-bold">Total</div>
            <div className="mx-3 font-bold">:</div>
          <div className="w-28 flex justify-start font-bold ">₹{user?.GetUser.pincode !== "600036" ? (1.02*(subTotalPrice+deliveryFees)).toFixed(2):(1.02*(subTotalPrice)).toFixed(2)}</div>
        </div>
        </div>
        
        <div className="flex flex-col justify-center my-5 w-full gap-2">
        {cartHasMerch &&(<p style={{ marginLeft: '5%',fontSize: '13px' }}>* for deliveries outside IITM, additional charges will be added</p>)}
        <div className="flex justify-center">
        <input 
          type="checkbox" 
          checked = {agreeToTerms}
          onChange={handleCheckboxChange} 
          id="termsCheckbox" 
        />
        <div>
          
        </div>
        
        <label htmlFor="termsCheckbox" style={{ marginLeft: '8px',fontSize: '13px' }}>
          Yes, I agree to the 
          <a href="https://saarang2024.s3.ap-south-1.amazonaws.com/Terms+%26+Conditions/Saarang+2025+T%26C.pdf" rel="noreferrer" target="_blank" style={{ color: 'yellow', textDecoration: 'underline', marginLeft: '4px' }}>
            terms and conditions.
          </a>
        </label>
      </div>
        <div className="flex justify-center">
        <button disabled={!agreeToTerms} className={`Proceed-to-Pay-Button flex justify-center ${agreeToTerms?"cursor-pointer":""}`} style={{backgroundColor:agreeToTerms?' #8B0000': '#602626'}}onClick={Checkout}>
          Proceed To Pay
        </button>
        </div>
        </div>
    </div>
      </div>
      </div>
      
  );
};

export default Cart;
