import React, { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../graphql/axiosConfig";
import { useNavigate } from "react-router-dom";
import EditProShow from "./editProshow";

interface ProShow {
  Description: string;
  Image: string;
  mobileImage?:string;
  Name: string;
  Position: string;
  Price: number;
  Smail_price: number;
  id: string;
  active: string | null;
  soldTicketsCount?: number;
  available?:number;

}

interface Order {
  Name: string;
  Price: number;
  Quantity: number;
  Size: string;
  customization: string;
}

const ProShowsAdminPage: React.FC = () => {
  const [proShows, setProShows] = useState<ProShow[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editProShow, setEditProShow] = useState<ProShow | null>(null);
  const [newProShow, setNewProShow] = useState<ProShow>({
    Position: "",
    Price: 0,
    Name: "",
    Smail_price: 0,
    Description: "",
    Image: "",
    id: "", // Ensure id is included for consistency
    active: null, 
    available:0,
  });

  useEffect(() => {
    fetchProShows();
  }, []);
const navigate = useNavigate()
  const fetchProShows = async () => {
    const query = `
      query GetProShows {
        getProShows {
          Description
          Image
          mobileImage
          Name
          Position
          Price
          Smail_price
          id
          active
          available
        }
      }`;

    const response = await axiosInstance.post("", {
      query,
    });
    const fetchedProShows: ProShow[] = response.data.data.getProShows;

    // Fetch sold tickets count for each ProShow
    const soldTicketsCounts = await Promise.all(
      fetchedProShows.map(async (proShow) => {
        const ordersQuery = `
          query GetOrdersByItem($itemId: String!) {
            getOrdersByItem(ItemID: $itemId) {
              Name
              Price
              Quantity
              Size
              customization
            }
          }`;
    
        const orderResponse = await axiosInstance.post(
          "",
          {
            query: ordersQuery,
            variables: { itemId: proShow.id, itemType: "ProShow" },
          }
        );
    
        const totalQuantity = orderResponse.data.data.getOrdersByItem.reduce(
          (sum:any, order:any) => sum + order.Quantity,
          0
        );
    
        return {
          id: proShow.id,
          soldTicketsCount: totalQuantity,
        };
      })
    );
    

    const updatedProShows = fetchedProShows.map((proShow) => {
      const orderCount = soldTicketsCounts.find(
        (count) => count.id === proShow.id
      );
      return {
        ...proShow,
        soldTicketsCount: orderCount?.soldTicketsCount || 0,
      };
    });

    setProShows(updatedProShows);
  };
  const handleSaveProShow = () => {
    fetchProShows()
    alert("Proshow added successfully!!!")
    setEditProShow(null);
  };
  function getEmailType(email:any) {
    if (email.endsWith('@smail.iitm.ac.in')) {
        return 'smail';
    } else if (email.endsWith('@alumni.iitm.ac.in')) {
        return 'alumni';
    } else {
        return 'public';
    }
}
  const handleDownloadCSV = async (proshow: ProShow) => {
    const query = `
      query GetOrdersCsvByItem($itemId: String!) {
        getOrdersCsvByItem(ItemID: $itemId) {
          order {
            Name
            Price
            Quantity
            Size
          }
          user {
            username
            email_id
            contact_no
            address
            pincode
          }
          payment{
          OrderDate
          }
        }
      }
    `;
  
    const response = await axiosInstance.post("", {
      query,
      variables: { itemId:proshow.id },
    });
  
    const orders = response.data.data.getOrdersCsvByItem;
  
    // If no orders are available, return early.
    if (!orders || orders.length === 0) {
      alert("No orders found for this item.");
      return;
    }
  
    // Prepare CSV rows with the user details
    const csvRows = [
      ["Order Name","User Name","Type", "Email", "Contact No.", "Price (₹)", "Quantity", "Amount","Date"],
      ...orders.map((order: any) => [
        order.order.Name+"-"+proshow.Position,
        order.user.username,
getEmailType(order.user.email_id),
        order.user.email_id,
        order.user.contact_no,
        order.order.Price,
        order.order.Quantity,
        order.order.Price * order.order.Quantity,
        order.payment.OrderDate
      ]),
    ];
  
    // Convert the rows to a CSV string
    const csvString = csvRows.map((row) => row.join(",")).join("\n");
  
    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
  
    // Generate the filename as `order.<Name>_<date>.csv`
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    const orderName = proshow.Name+"-"+proshow.Position;
    const filename = `${orderName}_${formattedDate}.csv`;
  
    // Create a download link and trigger it
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.click();
  };
  const handleAllCSV = async () => {
    const query = `
      query GetOrdersCsv {
        getOrdersCsv {
          order {
            Name
            ItemType
            Price
            Quantity
            Size
            customization
          }
          user {
            username
            email_id
            contact_no
            address
            pincode
          }
          payment{
          OrderDate
          }
          position
        }
      }
    `;
  
    const response = await axiosInstance.post("", {
      query,
    });
  
    const orders = response.data.data.getOrdersCsv;
  
    // If no orders are available, return early.
    if (!orders || orders.length === 0) {
      alert("No orders found for this item.");
      return;
    }
  
    // Prepare CSV rows with the user details
    const csvRows = [
      ["Order Name","User Name","Type", "Email", "Contact No.", "Price (₹)", "Quantity", "Amount","Discount","Date","Size","customization","pincode","IITM","Address"],
      ...orders.map((order: any) => [
        order.order.Name+(order.position?"-"+order.position:""),
        order.user.username,
getEmailType(order.user.email_id),
        order.user.email_id,
        order.user.contact_no,
        order.order.Price,
        order.order.Quantity,
        order.order.Price * order.order.Quantity*(order.order.Quantity >= 5 && order.order.Quantity &&order.order.ItemType==="Combo" ? 0.95 : 1),
        order.order.Price*order.order.Quantity*(order.order.Quantity>=5 && order.order.Quantity && order.order.ItemType==="Combo" ?0.05:0),
        order.payment.OrderDate,
        order.order.Size,
        order.order.customization,
        order.user.pincode,
        order.user.pincode&&(order.user.pincode.includes("600036") ? "1":"0"),
        order.user.address,
      ]),
    ];
  
    // Convert the rows to a CSV string
    const csvString = csvRows.map((row) => row.join(",")).join("\n");
  
    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    const orderName = "all_orders";
    const filename = `${orderName}_${formattedDate}.csv`;
  
    // Create a download link and trigger it
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.click();
    // Generate the filename as `order.<Name>_<date>.csv`
  }
  const activateProShowMutation = `
  mutation ActivateProShow($activateProShowId: String!) {
    activateProShow(id: $activateProShowId)
  }`;
  const handleActivate = async (itemId: string) => {
    const confirmDeactivate = window.confirm("Are you sure you want to activate this ProShow?");
  if (!confirmDeactivate) return;
    await axiosInstance.post("", {
      query: activateProShowMutation,
      variables: { activateProShowId: itemId },
    });

    // Refresh the list after activating
    fetchProShows();
  };
  const deactivateProShowMutation = `
  mutation DeactivateProShow($deactivateProShowId: String!) {
    deactivateProShow(id: $deactivateProShowId)
  }`;
  const handleDeactivate = async (itemId: string) => {
    const confirmDeactivate = window.confirm("Are you sure you want to deactivate this ProShow?");
  if (!confirmDeactivate) return;
    await axiosInstance.post("", {
      query: deactivateProShowMutation,
      variables: { deactivateProShowId: itemId },
    });

    // Refresh the list after updating
    fetchProShows();
  };
  const handleEditProShow = (proShow: ProShow) => {
    setEditProShow(proShow); // Set the ProShow to be edited
  };
  const handleAddProShow = async () => {
    if (!newProShow.Name || !newProShow.Position || !newProShow.Price|| !newProShow.Description||!newProShow.Smail_price||!newProShow.available) {
        alert("Please fill in all required fields.");
        return;
      }
    const mutation = `
      mutation AddProShow($position: String!, $price: Float!, $name: String!, $smailPrice: Float, $description: String, $image: String,$available: Float, $mobileImage: String) {
        AddProShow(position: $position, price: $price, name: $name, smail_price: $smailPrice, description: $description, image: $image,available: $available,mobileImage:$mobileImage) {
          Name
        }
      }`;
   const response =  await axiosInstance.post("", {
    query: mutation,
    variables: { 
      position: newProShow.Position,
      price: newProShow.Price,  // Ensure it's a float
      name: newProShow.Name,
      smailPrice: newProShow.Smail_price || null,  // Ensure it's a float or null
      description: newProShow.Description || "",  // Default empty if not provided
      image: newProShow.Image || "",
      available  :newProShow.available,
      mobileImage:newProShow.mobileImage||"",
    },
    });
    if (response.data.errors) {
        const errorMessage = response.data.errors.map((err:any) => err.message).join(", ");
        alert(`Error: ${errorMessage}`);
      } else {
        // Close modal and refresh ProShows if no errors
        setShowAddModal(false);
        alert("proshow added successfuly!")
        fetchProShows();
      }

    
  };
  async function LogOutHandler(e: React.FormEvent) {
    
    e.preventDefault();
    try {
      const res = await axiosInstance.post("", {
        query: `mutation Mutation { Logout }`
      });

      if (res.data.errors) {
        // Handle errors if necessary
      }
      navigate('/auth');
    } catch (e: any) {
    }
  } 
  return (
    <div className="min-h-screen p-8 bg-gray-800 text-black flex flex-col">
      <div className="flex justify-between items-center mb-4">
        <div className="flex justify-center gap-5">
        <button
          onClick={() => setShowAddModal(true)}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          Add ProShow
        </button>
        <button
          onClick={handleAllCSV}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          CSV
        </button>
        </div>
        <h1 className="text-3xl text-center">ProShows Admin Panel</h1>
        <nav className="space-x-4">
  <button
    className="bg-blue-100 text-gray-800 px-4 py-2 rounded"
    onClick={() => navigate("/admin/combo")}
  >
    Combos
  </button>
  <button
    className="bg-blue-100 text-gray-800 px-4 py-2 rounded"
    onClick={() => navigate("/admin/merch")}
  >
    Merch
  </button>
  <button
    className="bg-blue-100 text-gray-800 px-4 py-2 rounded"
    onClick={() => navigate("/")}
  >
    Portal
  </button>
  <button
    className="bg-red-400 text-gray-800 px-4 py-2 rounded"
    onClick={LogOutHandler}
  >
    Logout
  </button>
</nav>

      </div>

      <table className="min-w-full bg-gradient-to-r from-red-300 to-red-400 rounded-lg shadow-lg">
        <thead>
          <tr className="bg-gradient-to-r from-red-400 to-red-500 text-black">
            <th className="py-3 px-6 text-left w-1/6">Name</th>
            <th className="py-3 px-6 text-left w-1/6">Position</th>
            <th className="py-3 px-6 text-left w-1/6">Price (₹)</th>
            <th className="py-3 px-6 text-left w-1/6">Smail Price (₹)</th>
            <th className="py-3 px-6 text-left w-1/6">Available</th>
            <th className="py-3 px-6 text-left w-1/6">Sold</th>
            <th className="py-3 px-6 text-left w-1/6">Image</th>


            <th className="py-3 px-6 text-left w-1/6">csv</th>
            <th className="py-3 px-6 text-left w-1/6">Edit</th>
            <th className="py-3 px-6 text-left w-1/6">Activity</th>
          </tr>
        </thead>
        <tbody>
          {proShows.map((proShow) => (
            <tr
              key={proShow.id}
              className="border-b border-red-400 hover:bg-red-200 transition-colors duration-300"
            >
              <td className="py-4 px-6 font-bold">{proShow.Name}</td>
              <td className="py-4 px-6 font-bold">{proShow.Position}</td>
              <td className="py-4 px-6">₹{proShow.Price}</td>
              <td className="py-4 px-6">₹{proShow.Smail_price}</td>
              <td className="py-4 px-6 ">{proShow.available}</td>
              <td className="py-4 px-6 ">{proShow.soldTicketsCount}</td>
              <td className="py-4 px-6">
                <img src={proShow.Image} alt={proShow.Name} className="w-16" />
              </td>
            

              <td className="py-4 px-6">
                <button
                  onClick={() => handleDownloadCSV(proShow)}
                  className="bg-red-500 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
                >
                  CSV
                </button>
              </td>
              <td className="py-4 px-6">
                <button
                  onClick={() => handleEditProShow(proShow)}
                  className="bg-yellow-500 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
                >
                  Edit
                </button>
              </td>
              <td className="py-4 px-6">
                {proShow.active === null ||
                (proShow.active.toLowerCase() !== "false" &&
                  proShow.active.toLowerCase() !== "inactive") ? (
                  <>
                    <button
                      onClick={() => handleDeactivate(proShow.id)}
                      className="bg-red-600 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
                      style={{ width: '120px' }}
                    >
                      Deactivate
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => handleActivate(proShow.id)}
                      className="bg-green-600 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
                      style={{ width: '120px' }}
                    >
                      Activate
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editProShow && (
        <EditProShow
          proShow={editProShow}
          onClose={() => setEditProShow(null)}
          onSave={handleSaveProShow}
        />
      )}
      {showAddModal && (
       <div className="fixed inset-0 flex items-center justify-center z-50">
       <div className="bg-white rounded-lg shadow-lg p-8">
         <h2 className="text-xl mb-4">Add ProShow</h2>
         <div>
           {/* Dropdown for Name */}
           <select
             value={newProShow.Name}
             onChange={(e) =>
               setNewProShow({ ...newProShow, Name: e.target.value })
             }
             className="border p-2 w-full mb-2"
           >
             <option value="" disabled>
               Select Name
             </option>
             <option value="rock">Rock</option>
             <option value="pop">Pop</option>
             <option value="edm">EDM</option>
             <option value="choreo">Choreo</option>
           </select>
     
           {/* Dropdown for Position */}
           <select
             value={newProShow.Position}
             onChange={(e) =>
               setNewProShow({ ...newProShow, Position: e.target.value })
             }
             className="border p-2 w-full mb-2"
           >
             <option value="" disabled>
               Select Position
             </option>
             <option value="fanpass">Fanpass</option>
             <option value="bowl">Bowl</option>
             <option value="gallery">Gallery</option>
             <option value="gallery+">Gallery+</option>
             <option value="platinum">Platinum</option>
             <option value="gold">Platinum</option>
             <option value="chair">Chair</option>



           </select>
     
           <input
             type="number"
             placeholder="Price"
             value={newProShow.Price}
             onChange={(e) =>
               setNewProShow({
                 ...newProShow,
                 Price: parseFloat(e.target.value),
               })
             }
             className="border p-2 w-full mb-2"
           />
           <input
             type="number"
             placeholder="Smail Price"
             value={newProShow.Smail_price}
             onChange={(e) =>
               setNewProShow({
                 ...newProShow,
                 Smail_price: parseFloat(e.target.value),
               })
             }
             className="border p-2 w-full mb-2"
           />
           <input
             type="text"
             placeholder="Description"
             value={newProShow.Description}
             onChange={(e) =>
               setNewProShow({ ...newProShow, Description: e.target.value })
             }
             className="border p-2 w-full mb-2"
           />
           <input
             type="text"
             placeholder="Image URL"
             value={newProShow.Image}
             onChange={(e) =>
               setNewProShow({ ...newProShow, Image: e.target.value })
             }
             className="border p-2 w-full mb-2"
           />
            <input
             type="text"
             placeholder="Mobile Image URL"
             value={newProShow.mobileImage}
             onChange={(e) =>
               setNewProShow({ ...newProShow, mobileImage: e.target.value })
             }
             className="border p-2 w-full mb-2"
           />
            <input
             type="number"
             placeholder="Available tickets"
             value={newProShow.available}
             onChange={(e) =>
               setNewProShow({
                 ...newProShow,
                 available: parseFloat(e.target.value),
               })
             }
             className="border p-2 w-full mb-2"
           />
           <div className="flex justify-end">
             <button
               onClick={handleAddProShow}
               className="bg-red-500 text-white px-4 py-2 rounded"
             >
               Add
             </button>
             <button
               onClick={() => setShowAddModal(false)}
               className="bg-gray-400 text-white px-4 py-2 rounded ml-2"
             >
               Cancel
             </button>
           </div>
         </div>
       </div>
     </div>
     
      )}
    </div>
  );
};

export default ProShowsAdminPage;
