import React, { useState, useEffect } from "react";
import "./order.scss";
import axiosInstance from "../../graphql/axiosConfig";
import Navbar from "../../components/Navbar/Navbar";
import Popup from "../../ui-elements/popup/popup";

interface Order {
  Name: string;
  Description: string;
  image: string;
  Customization: string; 
  Quantity: number;
  Price: number;
  PaymentStatus: string;
}

const MyOrders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [PaymentStatusColor, setPaymentStatusColor] = useState("");
  const [isUser, setIsUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function IsUserLoggedIn() {
    const response = await axiosInstance.post("", {
      query: `
      mutation Mutation {
        IsUserLoggedIn
      }
      `,
    });

    if (!response.data.errors) setIsUser(true);
  }

  async function getOrders() {
    setIsLoading(true);
    const response = await axiosInstance.post("", {
      query: `
        query Query {
          getUserOrders {
            Price
            Quantity
            ItemID
            ItemType
            razorpay_OrderID
            customization
            Size
          }
        }
      `,
    });

    console.log(response.data.data.getUserOrders)
    if (response.data.data.getUserOrders) {
      setIsLoading(false);

      const order_list: Order[] = [];

      for (let i = 0; i < response.data.data.getUserOrders.length; i++) {
        let item = response.data.data.getUserOrders[i];

        if (item.ItemType === "ProShow") {
          const response_sub = await axiosInstance.post("", {
            query: `
                    query GetProShowbyId($id: String!) {
                        getProShowbyId(id: $id) {
                            Name
                            Position
                            Image
                        }
                    }
                    `,
            variables: {
              id: item.ItemID,
            },
          });

          const payment_status = await axiosInstance.post("", {
            query: `query Query($razorpayOrderId: String!) {
                        getPaymentStatusByRazorpayId(razorpay_OrderID: $razorpayOrderId)
                    } `,
            variables: {
              razorpayOrderId: item.razorpay_OrderID,
            },
          });

          let order = {
            Name: response_sub.data.data.getProShowbyId.Name,
            Description: response_sub.data.data.getProShowbyId.Position,
            Customization: "N/A",
            Quantity: item.Quantity,
            Price: item.Price,
            PaymentStatus:
              payment_status.data.data.getPaymentStatusByRazorpayId,
            image: item.Image,
          };

          order_list.push(order);
        } else if (item.ItemType === "Combo" || item.ItemType === "MerchCombo") {
          const response_sub = await axiosInstance.post("", {
            query: `
                    query GetCombobyId($getCombobyIdId: String!) {
                        getCombobyId(id: $getCombobyIdId) {
                            Description
                            Name
                            Price
                          
                            active
                            Image
                        }
                        }
                    `,
            variables: {
              getCombobyIdId: item.ItemID,
            },
          });

          let combo = response_sub.data.data.getCombobyId;
          const payment_status = await axiosInstance.post("", {
            query: `query Query($razorpayOrderId: String!) {
                        getPaymentStatusByRazorpayId(razorpay_OrderID: $razorpayOrderId)
                    } `,
            variables: {
              razorpayOrderId: item.razorpay_OrderID,
            },
          });

          let customization = "";
          let description;

          if (item.customization === null && item.Size === "N/A") {
            customization = "N/A";
          } else if (item.customization === null) {
            customization = item.Size;
          } else {
            customization = item.customization + "-" + item.Size;
          }
          let order = {
            Name:  response_sub.data.data.getCombobyId.Name,
            Description: customization,
            Customization: customization,
            Quantity: item.Quantity,
            Price: item.Price,
            PaymentStatus:
              payment_status.data.data.getPaymentStatusByRazorpayId,
            image: item.Image,
          };
          order_list.push(order);
        } else if ((item.ItemType === "Merch")) {
          const response_sub = await axiosInstance.post("", {
            query: `
                    query GetMerchByID($getMerchByIdId: String!) {
                        getMerchByID(id: $getMerchByIdId) {
                            Description
                            Name
                            Price
                            active
                            id
                            Image
                        }
                    }
                    `,
            variables: {
              getMerchByIdId: item.ItemID,
            },
          });

          let merch = response_sub.data.data.getMerchByID;

          const payment_status = await axiosInstance.post("", {
            query: `query Query($razorpayOrderId: String!) {
                        getPaymentStatusByRazorpayId(razorpay_OrderID: $razorpayOrderId)
                    } `,
            variables: {
              razorpayOrderId: item.razorpay_OrderID,
            },
          });

          let customization = "";

          if (item.customization === null && item.Size === "N/A") {
            customization = "N/A";
          } else if (item.customization === null) {
            customization = item.Size;
          } else {
            customization = item.customization + "-" + item.Size;
          }
          let order = {
            Name: merch.Name,
            Description: "N/A",
            Customization: customization,
            Quantity: item.Quantity,
            Price: item.Price,
            image: item.Image,
            PaymentStatus:
              payment_status.data.data.getPaymentStatusByRazorpayId,
          };

          order_list.push(order);
        }
      }
      setOrders(order_list);
      console.log(order_list)
    }
  }

  useEffect(() => {
    const initialize = async () => {
      await IsUserLoggedIn();
      if (isUser) {
        await getOrders();
      }
    };
    initialize();
  }, [isUser]);

  if (isUser === false) {
    return (
      <div className=" Order-Container relative items-center w-full h-screen bg-black ">
        <Navbar />
        <div className="Order-Section">
          <div className="Order-Heading">ORDERS</div>
          <div className="w-[70vw] h-[50vh] text-white font-bold text-xl font-urbanist py-4 px-6 rounded-lg shadow-lg bg-white bg-opacity-10 flex justify-center items-center">
            Please login to check your orders!!
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className=" Order-Container relative items-center w-full h-screen bg-black ">
      <Navbar />
      {isLoading && <Popup message={""} type={"loading"} />}
      <div className="Order-Section">
        <div className="Order-Heading">ORDERS</div>

        <div className="Orders-Table">
          <div className="scrollable-table-container">

          <table className="custom-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Size/Position</th>
                <th>Quantity</th>
                <th>Price (INR)</th>
                <th>Payment Status</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index}>
                  <td>{order.Name}</td>
                  <td>{order.Customization == "N/A" ?order.Description:order.Customization}</td>
                  <td>{order.Quantity}</td>
                  <td>{order.Price}</td>
                  <td style={{ color: getPaymentStatusColor(order.PaymentStatus) }}>
                    {order.PaymentStatus}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  );
};

// Helper function to determine payment status color
function getPaymentStatusColor(status: string): string {
  switch (status) {
    case 'failed':
      return 'red';
    case 'pending':
      return 'yellow';
    default:
      return 'green';
  }
}

export default MyOrders;

