import React, { useEffect, useState }  from 'react';
import { motion } from "framer-motion";
import Navbar from '../Navbar/Navbar';
import heroimg from '../../Images/hero-bg.jpg'
import { RootState } from '../../redux/device/reducers';
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import './Hero.scss'
// import { RootState } from './store';
const gridSquareVariants = {
  hidden: {
    opacity: 0,
    y: 50, // Start slightly below the final position
  },
  show: {
    opacity: 1,
    y: 0, // Move to the final position
    transition: {
      duration: 1.5, // Adjust the duration for a slower fade-in
      ease: 'easeOut', // Smooth easing function
    },
  },
};

const textVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeIn",  // Ease in effect
    },
  },
};

const Hero: React.FC = () => {
 
  return (
    <div className='hero-c'>
      <div id="Hero" className="relative items-center w-full h-screen">
        
        {/* Navbar Container */}
        <div className="z-20 absolute top-0 left-0 right-0 flex justify-center mt-4">
          <Navbar />
        </div>

       

        
    
        {/* Hero Section */}
        <motion.div  className="lg:flex lg:flex-row md:flex-col sm:flex-col w-full h-full overflow-hidden"  
      >
          <div 
            style={{ backgroundImage: `url(${heroimg})` }}
            className="w-full h-full bg-cover bg-center flex  items-center  opacity:40  border-4 border-transparent rounded-lg backdrop-blur-md justify-center"
          > 
          
            <motion.div
              variants={gridSquareVariants}
              initial="hidden"
              animate="show"
              className="filter drop-shadow-lg xs:mr-[0vw] xs:mt-[5vh] mt-[40vh] xs:mr-[30vw] lg:mr-[40vw] ml-[10vw] lg:mt-0"
            >
              <motion.h1
                initial="hidden"
                animate="visible"
                variants={textVariants}
                className="text-white font-bold text-5xl lg:text-7xl"
              >
                Saarang Sales <br /> Are Live!
              </motion.h1>

              <motion.p
                initial="hidden"
                animate="visible"
                variants={textVariants}
                transition={{ delay: 0.7 }}
                className="sub-title text-lg text-[#E7D5C6] xs:ml-[0] xs:w-[60vw] md:w-[35vw] mx-auto md:mt-4 xs:mt-0"
              >
                Unlock 5 days and 5 nights of epic proshows and exclusive merch. Get yours now and be part of the excitement before it's too late!
              </motion.p>
            </motion.div>
          </div>
        </motion.div>
      </div >
    </div>
  );
};

export default Hero;
